@import '../../../../assets/scss/custom/components/helperFontsize';

.user-profile {
    .text-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 250px; 
        @media screen and (max-width: 576px) {
            min-width: 200px;
        }
    }

    .text-truncate-plan-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 250px; 
        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }
      
    .cursor-pointer {
        cursor: pointer;
    }
      
    @media (max-width: 1023.99px) {

        .customer-detail-section,
        .shop-mandatory-detail,
        .shop-city-col,
        .shop-country-col {
            padding-right: 0 !important;
        }
    }

    .customer-detail-section {
        .password-field-padding {
            @media (min-width: 1023.99px) {
                padding-right: 3.5rem !important;
            }
        }

        // info icon
        .register-info-icon {
            font-size: $font-size-14;
            top: 2px;
            left: 2px;
            cursor: pointer;

            // info tooltip
            .info_tooltip {
                font-size: $font-size-12;
                position: absolute;
                background: var(--primary-black) !important;
                box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
                border-radius: 4px;
                white-space: nowrap;
                pointer-events: none;
                transition: 0s;
                display: none;
                word-wrap: break-word;
                white-space: normal;
                font-family: var(--bs-body-font-family);
            }
        }
    }

    @media (min-width: 610px) and (max-width: 1025.99px) {
        width: 100% !important;
        max-width: 100% !important;
    }

    @media (min-width: 1281px) {
        width: 80% !important;
        max-width: 80% !important;
    }

    .register-info-icon:hover {
        .info_tooltip {
            top: 19px !important;
            z-index: 9;
            display: block;
            pointer-events: auto;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
            right: -338px !important;
            color: var(--primary-white);
            width: 431px !important;
        }

        @media (min-width: 260.98px) and (max-width: 340px) {
            .info_tooltip {
                right: -141px !important;
                width: 238px !important;
            }
        }

        @media (min-width: 340.98px) and (max-width: 361px) {
            .info_tooltip {
                top: 17px !important;
                right: -235px !important;
                width: 344px !important;
            }
        }

        @media (min-width: 361.98px) and (max-width: 510px) {
            .info_tooltip {
                right: -255px !important;
                width: 349px !important;
            }
        }
    }

    .right-5px {
        right: 5px !important;
    }

    // password eye icon
    .edit-email {
        transform: translate(-50%);
        right: 27px;
        top: 38.93px;
        position: absolute;
        cursor: pointer;
        font-size: $font-size-16;
    }

    // drag and drop icon
    .dropzone {
        border: 2px dashed var(--secondary-white);
        border-radius: 6px !important;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (min-width: 1200px) {
            .display-4 {
                font-size: 2.5rem !important;
            }
        }

        .dropzone-text {
            font-size: 78% !important;
        }
    }

    .profile-pic-dropzone,
    .profile-pic-preview,
    .profile-pic-img {
        border-radius: 50% !important;
        width: 110px !important;
        height: 110px !important;
    }

    .profile-pic-delete-icon {
        right: 4px;
        bottom: 0;
        border-radius: 50%;
        background: var(--primary-light-red);
    }

    .dropzone-previews {
        display: grid;

        .height-6rem {
            height: 5rem !important;
        }

        .profilePicName {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .invalid-file {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: var(--bs-form-invalid-color);
    }

    // remove arrow from input type number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    textarea {
        resize: vertical !important;
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .notes-info-icon {
        top: 3px;
        margin-left: 5px;
    }

    .quotes_info_tooltip {
        display: none
    }

    .notes-info-icon:hover .quotes_info_tooltip {
        display: block;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: 13px;
        z-index: 10000;
        max-width: 200px;
        min-width: 200px;
        white-space: normal;
        overflow-wrap: break-word !important;
        position: absolute;
        font-family: var(--bs-body-font-family);
        font-size: normal;
        left: -50px;
        margin-top: 7px;
        padding: 5px 10px;
        line-height: 1.5;
    }

    .add-text-button {
        color: var(--primary-green);
        font-weight: bold;
        border: none !important;

        &:hover {
            color: var(--secondary-green);
        }
    }

    .shop_logo {
        width: 100px !important;
        max-width: 100px !important;
    }

    .image-error-msg {
        font-size: 80%;
    }

    .purchase-plan-link {

        &:hover {
            text-decoration: underline !important;
            border-bottom: var(--secondary-green);
        }
    }
}

.subscription-card-body {
    border: 1px solid var(--primary-yellow);
    background-color: var(--primary-light-yellow);
}

.image-crop-modal {
    .slider {
        background-color: initial;
    }
}

.purchase-plan-container {
    color: var(--primary-green);
    text-decoration: underline !important;
}

.personal-information-container{
    @media screen and (min-width: 1400px) and (max-width: 1500px) {
        width: 63% !important;
    }
}

.subscription-information-container{
    @media screen and (min-width: 1400px) and (max-width: 1500px) {
        width: 37% !important;
    }
}

.clock-icon{
    top: -1px;
}

.remaining-days-countdown{
    color: #f46a6acc;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--secondary-green) !important;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    border-radius: 50%;
    background: var(--secondary-green) !important;
    cursor: pointer;
}

// modal
.confirm-password-modal {
    top: 32% !important;

    // password eye icon
    .login-eye {
        transform: translate(-50%);
        right: 27px;
        top: 54.93px;
        position: absolute;
        cursor: pointer;
        font-size: $font-size-16;
    }

    @media (min-width: 539px) and (max-width: 541px) {
        .login-eye {
            top: 34.93px;
        }
    }

    @media (min-width: 610px) {
        width: 72% !important;

        .login-eye {
            top: 34.93px;
        }
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }
}