.create-subscription-plan-modal{
    .pricing-standard-card {
        box-shadow: inset rgba(255, 223, 87, 0.1) 25px 25px 50px 100px;
    }

    .payable-amount-section {
        width: 300px;

        @media screen and (min-width: 576px) and (max-width: 768px) {
            width: 240px;
        }
    }

    .total-container{
        
        @media screen and (min-width: 576px) and (max-width: 768px) {
            width: 240px;
        }
    }

    .subtotal-container{
        @media screen and (min-width: 576px) and (max-width: 768px) {
            width: 240px;
        }
    }
    
    .plan-info-icon{
        top: 2px !important;
    }
    
    .start-date-field {
        width: 135px !important;
    }
    
    .form-check-input:checked {
        border: var(--primary-green) !important;
        background-color: var(--primary-green) !important;
    }
    
    .choose-pricing-plan {
        padding-left: 20px !important;
    }
    
    .price{
        @media screen and (min-width: 650px) and (max-width: 820px) {
            font-size: 20px !important;
        }
    }

    .plan-details-container {
        height: 90px; 
    }
    
    .start-date-container {
        margin-right: 62px !important;
    }
    
    .discount-info {
        padding-right: 30px !important;
    }
    
    .payment_checkbox {
        @media screen and (max-width: 576px) {
            height: 15px !important;
            width: 20px !important;
        }
    }
    
    .range-picker{
        max-width: 300px;
        width: 260px;
    
        @media screen and (max-width: 820px) {
            width: 300px !important;
            margin-top: 15px;
            margin-left: 5px;
        }
    
        @media screen and (max-width: 576px) {
            width: 230px !important;
            margin-top: 15px;
            margin-left: 5px;
        }
        
    }

    
 
    .plan-duration-switch{
        .plan-duration-button{
            color: var(--primary-green);
            border-color: var(--primary-green);
            &:before{
                height: 20px;
                width: 20px;
                line-height: 20px;
                font-size: 9px;
                background-color: var(--primary-gray);
                color: var(--primary-black);
            }
            &:hover,&.active,&:active{
                color: var(--primary-white);
                background-color: var(--primary-green) ;
            }
        }
    }
    // for type-options-switch 
    .card-radio-duration{
        padding-inline: 20px;
        border-bottom: 2px solid #eff2f7 ;
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
        border-radius: 0px !important;
    }
    
    .card-radio {
        border-radius: 4px;
        border: 2px solid #eff2f7;
        padding: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
        &:hover {
            cursor: pointer;
        }
    
        .plan-descrition {
            white-space: break-spaces;
        }
    }
    
    .card-radio-label {
        display: block;
    }
    
    
    .card-radio-input {
        display: none;
    
        &:checked+.card-radio {
            border: 2px solid var(--primary-yellow);
            border-radius: 4px;
        }
    
        &:checked+.card-radio-duration{
            border-radius: 0px !important;
            border-top: 0px !important;
            border-left: 0px !important;
            border-right: 0px !important;
            border-bottom: 2px solid var(--secondary-green) !important;
        }
    }
    
    .discount-container {
        background-color: #c3cbe429;
    }
    
    
    // for switch
    .react-switch-bg {
        height: 15px !important;
        width: 34px !important;
    }
    
    .checked .react-switch-bg {
        background: var(--secondary-green) !important;
    }

    .unchecked .react-switch-bg {
       background: var(--primary-gray) !important;
    }    
    
    .switch-icon {
        font-size: 24px;
        position: absolute;
        top: 1px;
        color: var(--secondary-light-green);
    }
    
    .mark_as_paid_icon{
        color: var(--primary-gray);
        margin-left: 3px;
        top: 2px;
    }
    
    .react-switch-handle {
        height: 11px !important;
        width: 11px !important;
        top: 6px !important;
        left: 6px;
    }
    
    @media (min-width: 1199.98px) and (max-width: 1399.98px) {
        .switch-icon {
            top: 0px;
        }
    }
    @media (max-width:991px){
        .subscription-plan-name{
            display: block !important;
            width: 100px !important;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        .card-radio-duration{
            font-size: 11px !important;
            padding-inline: 14px !important;
        }
        .choose-pricing-plan-label {
            align-self: self-start !important;
        }
        .plan-duration-switch{
            align-self: self-start;
            margin-left: 0px !important;
        }
        .range-picker{
            font-size: 12px;
            margin-top: 18px;
            width: 235px !important;
        }
    }
    @media  (max-width:768.99px) {
        .choose-plan-duration-section{
            margin-left: 15px !important;
        }
        .plan-duration-switch{
            margin-left: 22px;
        }
         .card-radio-duration{
            font-size: 11px;
         }
         .card-radio-duration{
            padding-inline: 10px !important;
        }
        .plan-duration-switch{
            align-self: self-start;
            margin-left: 0px !important;
        }
        .range-picker{
            font-size: 12px;
            margin-top: 18px;
            width: 232px !important;
        }
    }
    @media  (max-width:767px) {
        .subscription-plan-name{
            display: inline-block !important;
            width: 100% !important;
        }
    }
   
    @media (max-width:576px){
        .pricing-plan-section{
                margin-left: 0px !important;
        }
        .selected-radio-button{
            font-size: 11px !important;
        }
        .card-radio-duration{
            padding-inline: 0px 11px 8px!important;
        }
        .choose-pricing-plan-label{
            margin-left: 25px !important;
        }
        .plan-duration-switch{
            margin-top: 5px;
            margin-left: 0px !important;
        }   
        
  
    }
    @media (max-width:525px) {
        .range-picker {
            font-size: 10px;
            margin-top: 18px;
            width: 200px !important;
        }
    }
    @media (max-width:495px) {
        .range-picker {
            width: 190px !important;
        }
    }

    @media  (max-width:480px) {
            .selected-radio-button{
                font-size: 12px !important;
            }
            .pricing-plan-section{
                flex-direction: column;
            }
            .choose-plan-duration-wrapper{
                flex-direction: column !important;
                align-items: flex-start !important;
            }
            .plan-duration-switch{
                margin-left:25px !important;
            }   
            .range-picker {
                width: 240px !important;
                font-size: 12px;
            }
        }
        @media (max-width:430px) {
            .subscription-plan-name{
                display: block !important;
                width: 210px !important;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }
        @media (max-width:390px){     
        .subscription-plan-name{
            display: block !important;
            width: 190px !important;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        .type-options-switch{
            margin-left: 24px !important;
        }
    }
    @media (max-width:350px) {
        .subscription-plan-name{
            width: 150px !important;
        }
    }
}
