.add-client {

    @media screen and (min-width: 1023.99px) {
        max-width: 40% !important;
    }

    .css-13cymwt-control,
    .css-13cymwt-control:hover {
        border: none !important;
        box-shadow: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        appearance: textfield !important;
        -moz-appearance: textfield !important;
    }

    .increaseHeight {
        overflow-y: auto;

        @media screen and (min-width: 600px) and (max-width: 768px) {
            height: 590px;
        }

        @media screen and (max-width:600px) {
            height: 480px !important;
        }

        @media screen and (device-width: 1024px) and (device-height: 600px) {
            height: 430px;
        }
    }
}