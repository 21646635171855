.quote-calc-tab-view {
    padding-top: 0.5rem ;
    padding-bottom: 0.5rem ;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) and (max-height: 800px) {
    .quote-calc-tab-view {
        padding: 0px !important;
    }
 }
