@import '../../../../../src/assets/scss/custom/components/helperFontsize';

.login-page {
    height: 100vh;
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;

    .auth-link:hover {
        text-decoration: underline !important;
        border-bottom: var(--secondary-green);
    }

    // password eye icon
    .login-eye {
        transform: translate(-50%);
        right: 27px;
        top: 34.93px;
        position: absolute;
        cursor: pointer;
        font-size: $font-size-16;
    }

    .right-5px {
        right: 5px !important;
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .logo-img {
        height: 100px !important;

        @media screen and (min-width:607px) and (max-width:750px) {
            height: 120px !important;
        }

        @media screen and (min-width:751px) and (max-width:984px) {
            height: 135px !important;
        }
    }

    .forgot-password-link:hover .forgot-password-text {
        border-bottom: 1px solid var(--forgot-password-link);
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:autofill {
    -webkit-text-fill-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
}