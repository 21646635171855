$font-size-9: 9px !important;
$font-size-10: 10px !important;
$font-size-11: 11px !important;
$font-size-12: 12px !important;
$font-size-13: 13px !important;
$font-size-14: 14px !important;
$font-size-15: 15px !important;
$font-size-16: 16px !important;
$font-size-17: 17px !important;
$font-size-18: 18px !important;
$font-size-20: 20px !important;
$font-size-22: 22px !important;
$font-size-24: 24px !important;