.payment-page {

    .animation-container{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 150px; 
        margin-right: 150px;

        .animation_card{
            background-color: transparent !important;
            border: 2px solid #e5e7ed ;
            border-radius: 12px;

            .animation_card_body{
                padding: 50px !important;

                @media screen and (max-width: 820px) {
                    padding: 35px !important;
                }

                @media screen and (max-width: 576px) {
                    padding: 30px !important;
                }
            }
        }

        .payment_failed_message{
            font-size: 16px;
            font-weight: 400;
            
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }

        .payment_error_messages{
            font-size: 14px;

            @media screen and (max-width: 576px) {
                font-size: 12px;
            }
        }

        .payment_status_image{
            max-width: 110px;
        }

        @media screen and (max-width: 820px) {
            margin-left: 100px;
            margin-right: 100px;
        }

        @media screen and (max-width: 600px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .subscription-plan-container{
        border: 2px solid var(--primary-yellow) !important;
        border-radius: 12px !important;

        .subscription-plan-box {
            box-shadow: inset rgba(255, 223, 87, 0.1) 25px 25px 50px 100px !important;
            border-bottom: 2px solid var(--primary-yellow) !important;
        }

        .edit-plan-btn{
            border-color: var(--primary-yellow) !important;
            color: var(--primary-yellow) !important;
            
            &:hover{
                background-color: var(--primary-yellow) !important;
                color: white  !important;
            }
        }

        .plan-duration{
            font-weight: normal !important;

            @media screen and (max-width: 600px) {
                    font-size: 15px !important;
            }        
        }
    }

    .plan-name{
        font-weight: 600 !important;
    }

    .payment-error-message{
        color: var(--secondary-email-text);
    }

    .price {
        font-size: 32px !important;
        font-weight: 600 !important;

        @media screen and (min-width: 576px) and (max-width: 768px) {
            font-size: 17px !important;
        }

        @media screen and (max-width: 600px) {
            font-size: 17px !important;
        }
    }

    .choose-recipient-icon{
        position: relative; 
        top: 2px;
    }

    .add-credit-card-icon-container {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-green);

        .add-crdt-card-info-icon {
            position: relative;
        }
    }

    .payment-card-container{
        border-radius: 20px !important;
        color: white !important;
    }   

    .card-digit{
        font-size: 18px ;

        @media screen and (max-width: 576px) {
            font-size: 11px;
        }
    }

    .pay-now-btn{

        &:disabled{
            cursor: no-drop !important;
            pointer-events: all !important;
        }
    }

    .card-duration{
        @media screen and (max-width: 576px) {
            font-size: 11px;
        }
    }
    
    .carousel-control-next, 
    .carousel-control-prev {
        width: 4%;
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid var(--primary-yellow);
        background-color: var(--primary-yellow) ;
        color: white !important; 
        opacity: 1 !important;
        
        &:hover{
            background-color: transparent !important;
            color: var(--primary-yellow) !important;
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon{
            position: relative;
            background-color: var(--primary-yellow);
            height: 30px ;
            width: 30px ;
            border-radius: 50%;
        }
    }


    .no-card-found-container{
        color: var(--primary-gray) !important;
    }

    .selected-card{
        filter: drop-shadow(0rem 0rem 0.5rem var(--primary-gray));
    }

    .selected-card-icon-indicator::before{
        background-color: black;
        border-radius: 50%;
    }

    .carousel-item{
        top: 10px;
    }

    .carousel-indicators{
        margin-top: 20px !important;
        position: absolute !important;
        top: 145px !important;


        @media screen and (max-width: 768px) {
            top: 0px  !important;
            margin-top: -20px !important;
        }

        [data-bs-target]{
            background-color: var(--primary-gray) !important;
        }
    }

}

.vertically-center{
    height: 100vh;
    padding-top: 0px !important;
}