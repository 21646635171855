@import "../../assets/scss/theme.scss";

.dashboard-page {

    .welcome-card-col {
        width: 40%;
    }

    .customer-statistic-card {
        width: 20%;
    }

    @media screen and (max-width: 765px) {

        .welcome-card-col,
        .customer-statistic-card {
            width: 100%;
        }
    }

    @media screen and (min-width: 766px) and (max-width: 1353px) {
        .welcome-card-col {
            width: 100%;
        }

        .customer-statistic-card {
            width: 33.2%;
        }
    }

    .radio-btn-label:checked {
        background-color: var(--secondary-green);
        border-color: var(--secondary-green);
    }

    .avatar-title {
        background-color: var(--primary-light-yellow) !important;
        color: var(--primary-yellow) !important;
    }

    .equal-height-col {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .home-icon {
        color: var(--primary-green) !important;
    }

    .dashboard-result-card {
        // height: 16vh;
        height: 13vh;
    }

    .graph-tab.active,
    .graph-tab.active:hover,
    .graph-tab:focus {
        background-color: var(--secondary-green) !important;
        color: var(--primary-white) !important;
    }

    .graph-tab:hover {
        color: var(--secondary-green) !important;
    }

    .apexcharts-legend-text {
        color: #74788d !important;
        font-family: $font-family-base !important;
        font-size: 13px !important;
    }

    .apex-charts text {
        // fill: #adb5bd;
        fill: var(--bs-gray-500);
        // font-family: Poppins, sans-serif !important;
        font-family: var(--bs-font-sans-serif) !important;
    }

    .transaction-head {
        background-color: var(--secondary-light-green) !important;
    }

    .quote-date-div {
        width: 75px;
    }

}

.text_number {
    font-size: 36px !important;
    padding-top: 0px !important;
    font-weight: bold !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.accordion-button:not(.collapsed)::after,
.accordion-item:last-of-type,
.payment_info-button {
    background-color: var(--secondary-light-green);
}

.accordion-body,
.accordion-item:first-of-type .accordion-button {
    padding: 16px;
}

.status-badge {
    font-size: 9px !important;
    height: 15px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 9px 0px !important;
    max-width: 90px;
    min-width: 60px;
}

.react-datepicker__navigation-icon {
    top: 5px !important;
}

.react-datepicker-year-header {
    color: var(--primary-white) !important;
}

.react-datepicker__header,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--secondary-green) !important;
}

.react-datepicker__year-wrapper {
    max-width: 139px !important;
}

.total-subscriber-row {
    // border: 1px solid var(--bs-gray-500);
    border-radius: 7px;
}

.total-subscriber {
    border-right: 1px solid var(--bs-gray-200);
}

.year-date-picker .react-datepicker-wrapper {
    width: 26% !important;
}

.dashboard-page {

    .reset-button {
        top: 2px;
    }

    .setup-rated-container {
        .card-contianer {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

            .calculator-th {
                background-color: var(--bs-card-bg) !important;
                padding: 0.65rem 0rem !important;
                font-size: 12px;
            }

            .calculator-td {
                border: none;
                padding: 4px 2px !important;
                min-width: 60px;
                font-size: 12px !important;

                @media screen and (min-width: 1250px) and (max-height: 850px) {
                    min-width: 38px;
                }

                .input {
                    padding: 5px;
                    margin: 0 auto !important;
                    text-align: center;
                    height: 25px !important;
                    font-size: 12px;
                    border: 1px solid var(--primary-yellow);
                    background-color: var(--primary-light-yellow);
                    border-radius: 5px;
                }
            }

            .width-70 {
                width: 70%;
            }

            .width-30 {
                width: 30%;
            }
        }
    }

    .year-date-picker .react-datepicker-wrapper {
        width: 26% !important;
    }

    .monthly-earning-tooltip:hover .monthly-percentage-tooltip {
        display: block;
        position: absolute;
        z-index: 1;
    }

    .monthly-percentage-tooltip {
        // width: 200px !important;
        display: none;
        background: black;
        width: 253px;
        padding: 5px;
        border-radius: 4px;
        left: 41px;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: 13px !important;
        padding: 5px 10px;
    }

    // .dashboard-monthly-apex-charts {
    //     height: 1359px;
    // }



    @media (max-width: 992px) {
        .graph-mobile {
            padding-bottom: 0;
        }

        .dashboard-result-card {
            height: 11vh;
        }
    }

    // @media screen and (min-width: 992px) and (max-width: 1027px) {
    //     .dashboard-result-card {
    //         height: 9vh;
    //     }
    // }

    @media (min-width: 1025px) {
        .monthly-earning-card {
            height: 440px;
        }
    }

    .transaction-id {
        width: 14%;
    }

    .transaction-user {
        width: 16%;
    }

    .created-at {
        width: 14%;
    }

    .paid-amount {
        width: 14%;
    }

    .paid-on {
        width: 14%;
    }

    .payment-status {
        width: 14%;
    }

    .payment-method {
        width: 14%;
    }

    .white-space {
        white-space: nowrap;
    }

    .transaction-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        width: 90% !important;
        display: block;
    }

    /* CSS */
    .form-select.form-select-sm option:hover {
        background-color: var(--secondary-green);
        /* Change the background color on hover */
    }

    .form-select.form-select-sm option:checked {
        background-color: var(--secondary-green);
        /* Change the background color of the active option */
    }

    // .form-select.form-select-sm {
    //     height: auto;
    //     /* Allow the select box to resize based on the number of options */
    // }

    .form-select.form-select-sm {
        box-shadow: none !important;
    }

    .form-select.form-select-sm:focus option:checked {
        background-color: var(--secondary-green);
    }

    .dashboard-user-img {
        width: 66px;
    }

    .welcome-card {
        background: rgba(0, 194, 133, 0.1);
    }

    .welcome-card-p {
        word-break: auto-phrases;
        white-space: unset;
        color: var(--secondary-email-text);
    }

    .no_records {
        height: 260px;
        justify-content: center;
        display: flex;
        align-items: center;
        color: var(--primary-gray) !important;
    }

    .paymentInfo {
        background-color: #fdf1d1 !important;
    }

    .setup-account {
        text-decoration: underline;
        color: var(--primary-green);
    }
}

.donut-skeleton-loader {
    position: relative;
    width: 100px;
    height: 100px;
}

.donut-skeleton-loader .react-skeleton-loader {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.donut-skeleton-loader:nth-child(even) .react-skeleton-loader {
    animation: shimmer 1.5s infinite ease-in-out;
}

@keyframes shimmer {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}