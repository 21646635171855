.billed-to-container {
    width: 300px !important;
}

.standalone-invoice-component {
    font-size: 0.8347rem ;

    .payment_failed_message{
        font-size: 16px ;
        font-weight: 400;

        @media screen and (max-width: 600px) {
            font-size: 12px;
           
        }
    }

    .payment_error_messages{
        font-size: 14px;

        @media screen and (max-width: 576px) {
            font-size: 10px;
        }
    }

    .selected-recipient-container {
        border: 1px solid transparent;

        &:hover {
            border: 1px solid #ced4da;
        }

        &:hover .choose-recipient-icon {
            display: inline;
        }

        .choose-recipient-icon {
            display: none;
        }
    }

    .should_have_query{
        font-size: 11px;
    }

    .print_shop_email{
        font-size: 12px;
    }

    .make-payment-btn{
        color: var(--primary-green);
        text-decoration: underline;
    }

    .header-div {
        background-color: var(--primary-white);
        border-bottom: 1px solid var(--primary-gray);
    }

    .flatpicker-field {
        width: 150px;
    }

    .reference-flatpicker {

        @media screen and (max-width: 576px) {
            width: 100px;
        }
    }

    .error-message {
        font-size: 80%;
        color: var(--bs-form-invalid-color);
        width: inherit;
        white-space: break-spaces;
    }

    .invoice-name-error-message {
        width: 240px !important;
        font-size: 80%;
        color: var(--bs-form-invalid-color);

        @media screen and (max-width: 576px) {
            width: 170px !important;
            margin-left: 10px;
        }
    }

    .width-180 {
        max-width: 180px;
    }

    .preview-quote-page {
        font-size: 0.8347rem !important;

        .error-message {
            font-size: 80%;
            color: var(--bs-form-invalid-color);
            width: inherit;
            white-space: break-spaces;
        }

        .width-180 {
            max-width: 180px;
        }

        .notes-textarea {
            width: 550px;
            max-width: 550px;

            @media screen and (min-width: 768px) and (max-width: 1200px) {
                max-width: 400px;
                width: 400px;
            }

            @media screen and (min-width: 577px) and (max-width: 767.999px) {
                max-width: 300px;
                width: 300px;
            }

            @media screen and (max-width: 576px) {
                max-width: 100%;
                width: 100%;
            }
        }

        @media (max-width: 391px) {
            font-size: 0.6347rem !important;
        }

        .preview-quote-title {
            .shop-logo {
                .preview-quote-shop-logo {
                    height: 60px !important;
                }
            }
        }

        .job_name_input {
            height: 36px;
            max-width: 200px;
        }

        .add-button {
            width: max-content !important;
            max-width: 150px;
            background-color: transparent !important;
            color: var(--secondary-green) !important;
            margin: 5px;
            padding: 5px;
            display: flex;
            align-items: center;
            border: 1px solid var(--secondary-green) !important;
            box-shadow: none !important;


            &:hover {
                color: white !important;
                background-color: var(--secondary-green) !important;
                border: 1px solid var(--secondary-green) !important;
            }
        }

        .preview-quote-table {
            .width-100 {
                width: 100px;
            }


            .width-120 {
                width: 100px;
                max-width: 120px !important;
            }

            .preview-quote-file {
                opacity: 0;
                position: absolute;
                top: 14px;
                left: 12px;
                width: 28px;
                height: 28px;
                cursor: pointer !important;
            }

            .width-106 {
                width: 106px;
                max-width: 210px;
            }

            .width-200 {
                width: 200px;
                max-width: 200px;
            }

            .width-250 {
                width: 250px;
                max-width: 250px !important;
            }

            .width-300 {
                width: 350px;
                max-width: 350px !important;

                @media screen and (min-width:820px) and (max-width: 1025px) {
                    max-width: 300px !important;
                    width: 200px !important;
                }

                @media screen and (min-width: 576px) and (max-width:820px) {
                    max-width: 200px !important;
                    width: 100px;
                }

                @media screen and (max-width:576px) {
                    max-width: 200px !important;
                    width: 150px;
                }
            }

            .width-350 {
                width: 350px;
                max-width: 350px !important;

                @media screen and (min-width: 1025px) and (max-width: 1281px) {
                    width: 300px !important;
                }

            }

            .width-400 {
                width: 400px;
                max-width: 400px !important;
            }

            .width-500 {
                width: 500px;
                max-width: 500px !important;

                @media screen and (min-width: 576px) and (max-width: 820px) {
                    max-width: 200px !important;
                    width: 200px;
                }

                @media screen and (max-width:576px) {
                    max-width: 200px !important;
                    width: 150px;
                }
            }

            @media (max-width: 668px) {

                .table-data,
                .table-th {
                    padding: 0.5rem 0.5rem !important;
                }
            }

            @media (max-width: 391px) {

                .table-data,
                .table-th {
                    padding: 0.5rem 0.4rem !important;
                }
            }
        }

        .table-th {
            background-color: var(--secondary-light-green);
        }

        .preview-quote-spinner {
            width: 4rem;
            height: 4rem;
            top: 50%;
            right: 50%;
            color: var(--secondary-green);
        }

        .preview-img-delete-icon {
            left: 30px;
            bottom: 38px;
            border-radius: 50%;
            background: var(--primary-light-red) !important;
            font-size: 11px !important;
            line-height: 1;
            border-radius: 50%;
            padding: 4px 3px 2px 3px;
            display: none;
        }

        .preview-quote-img-preview-div {
            height: 28px;
            width: 28px;
        }

        .btnimg {
            color: var(--bs-gray);
            background: var(--bs-gray-100);
            pointer-events: none;
            width: 28px;
            height: 28px;
            opacity: 1;
            text-align: center;
            border-radius: 4px;
            padding-top: 1px;
            cursor: pointer;
        }

        input[type=file]::-webkit-file-upload-button {
            /* chromes and blink button */
            cursor: pointer;
        }

        .image-preview {
            height: 28px;
            width: 28px;
        }

        .preview-quote-img-preview-div:hover .preview-img-delete-icon {
            display: block;
        }

        .btn-fill {
            &:disabled {
                cursor: no-drop !important;
                pointer-events: all;
            }
        }

    }

    .error-message {
        font-size: 80%;
        color: var(--bs-form-invalid-color);
        width: inherit;
        white-space: break-spaces;
    }

    .width-180 {
        max-width: 180px;
    }

    .vertical-align-data {
        vertical-align: middle;
    }

    .notes-textarea {
        width: 550px;
        max-width: 550px;

        @media screen and (min-width: 768px) and (max-width: 1200px) {
            max-width: 400px;
            width: 400px;
        }

        @media screen and (min-width: 577px) and (max-width: 767.999px) {
            max-width: 300px;
            width: 300px;
        }

        @media screen and (max-width: 576px) {
            max-width: 100%;
            width: 100%;
        }
    }

    @media (max-width: 391px) {
        font-size: 0.6347rem !important;
    }

    .preview-quote-title {
        .shop-logo {
            .preview-quote-shop-logo {
                height: 60px !important;
            }
        }
    }

    .job_name_input {
        height: 36px;
        max-width: 200px;
    }

    .add-button {
        width: max-content !important;
        max-width: 150px;
        background-color: transparent !important;
        color: var(--secondary-green) !important;
        margin: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        border: 1px solid var(--secondary-green) !important;
        box-shadow: none !important;


        &:hover {
            color: white !important;
            background-color: var(--secondary-green) !important;
            border: 1px solid var(--secondary-green) !important;
        }
    }

    .table-header-rate {
        width: 200px;
        min-width: 100px !important;

        @media screen and (max-width: 768px) {
            width: 130px;
        }

        @media screen and (max-width:568px) {
            width: 100px;
        }
    }

    .subtotal-container {
        width: 300px;
        border-bottom: 3px solid var(--primary-gray);

        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }

    .total-container {
        width: 300px;

        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }

    .accordion-button:not(.collapsed)::after,
    .accordion-item:last-of-type,
    .payment_info-button {
        background-color: #fdf1d1 !important;
    }

    .invoice-item_description,
    .item_name {
        color: black;
    }

    .accordion-button::after {
        display: none !important;
    }

    .preview-quote-table {
        .width-100 {
            width: 100px;
        }


        .invoice-item_description,
        .item_name {
            color: black;
        }

        .invoice_description {
            color: var(--secondary-email-text);
        }

        .tax_detail_row {
            width: 180px !important;

            @media screen and (max-width: 576px) {
                width: 120px !important;
            }

            .tax-display-quote-items-popup {
                color: var(--primary-gray);
            }

            .tax-display-quote-items-elipsis {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 120px;
                max-width: 120px !important;
                text-align: start;
                color: var(--primary-gray);
            }
        }

        .add-text-button {
            color: var(--primary-green);
            font-weight: bold;
            margin-top: 3px !important;

            &:hover {
                color: var(--secondary-green);
            }
        }

        .width-120 {
            width: 100px;
            max-width: 120px !important;

            @media screen and (max-width: 576px) {
                width: auto !important;
                max-width: 70px !important;
                min-width: 70px !important;
            }

            @media screen and (min-width: 576px) and (max-width: 768px) {
                width: auto !important;
                max-width: 90px !important;
            }
        }

        .preview-quote-file {
            opacity: 0;
            position: absolute;
            top: 14px;
            left: 12px;
            width: 28px;
            height: 28px;
            cursor: pointer !important;
        }

        .width-106 {
            width: 106px;
            max-width: 210px;
        }

        .width-200 {
            width: 200px;
            max-width: 200px;
        }

        .width-250 {
            width: 250px;
            max-width: 250px !important;
        }

        .width-300 {
            width: 400px;
            max-width: 400px !important;

            @media screen and (min-width:820px) and (max-width: 1280px) {
                max-width: 300px !important;
                width: 200px;
            }

            @media screen and (min-width:820px) and (max-width: 1025px) {
                max-width: 300px !important;
                width: 200px;
            }

            @media screen and (min-width: 576px) and (max-width:820px) {
                max-width: 200px !important;
                width: 150px;
            }

            @media screen and (max-width:576px) {
                max-width: 200px !important;
                width: 150px;
            }
        }

        .width-350 {
            width: 350px;
            max-width: 350px !important;
        }

        .width-400 {
            width: 400px;
            max-width: 400px !important;
        }

        .width-450px {
            width: 450px;
        }

        .preview-table-th {
            @media screen and (min-width: 1400px) and (max-width: 1460px) {
                width: 450px !important;
                max-width: 450px !important;
            }
        }

        .width-300 {
            @media screen and (min-width: 1400px) and (max-width: 1460px) {
                width: 320px;
            }
        }

        .width-0 {
            width: 60px;
        }

        .width-500 {
            width: 550px;
            max-width: 550px !important;


            @media screen and (min-width: 576px) and (max-width: 820px) {
                max-width: 200px !important;
                width: 200px;
            }

            @media screen and (max-width:576px) {
                max-width: 200px !important;
                width: 150px;
            }
        }

        @media (max-width: 668px) {

            .table-data,
            .table-th {
                padding: 0.5rem 0.5rem !important;
            }
        }

        @media (max-width: 391px) {

            .table-data,
            .table-th {
                padding: 0.5rem 0.4rem !important;
            }
        }
    }

    .table-th {
        background-color: var(--secondary-light-green);
    }

    .preview-quote-spinner {
        width: 4rem;
        height: 4rem;
        top: 50%;
        right: 48%;
        color: var(--secondary-green);

        @media screen and (max-width: 539px) {
            right: 40%;
        }

        @media screen and (min-width: 539px) and (max-width: 819px) {
            right: 44%;
        }
    }

    .height-50 {
        height: 50px;
    }

    .height-40 {
        height: 40px;
    }

    .preview-img-delete-icon {
        left: 30px;
        // bottom: 60px;
        top: 30px;
        border-radius: 50%;
        background: var(--primary-light-red) !important;
        font-size: 11px !important;
        line-height: 1;
        border-radius: 50%;
        padding: 4px 3px 2px 3px;
        display: none;
    }

    .preview-quote-img-preview-div {
        height: 28px;
        width: 28px;
    }

    .btnimg {
        color: var(--bs-gray);
        background: var(--bs-gray-100);
        pointer-events: none;
        width: 28px;
        height: 28px;
        opacity: 1;
        text-align: center;
        border-radius: 4px;
        padding-top: 1px;
        cursor: pointer;
    }

    .client-search-bar {
        width: 286px;
        margin-left: 15px !important;
    }

    .choose-a-recipient {
        border: 1px solid var(--primary-gray);
        height: 40px;
        width: 286px;
        margin-left: 15px !important;

        .down-icon {
            position: relative;
            top: -5px;
        }

        &:hover {
            border: 1px solid var(--primary-gray);
        }
    }

    .select-client-dropdown-menu {
        width: 286px;
        margin-left: 15px !important;
        max-height: 260px;
        overflow-x: hidden !important;
        overflow-y: auto !important;

        .dropdwon-option,
        .add-new-client-btn {
            width: 260px;
        }

        .dropdwon-option {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid var(--primary-yellow);
                background-color: var(--primary-light-yellow);
            }

            .option-client-email {
                color: var(--secondary-email-text);
            }

            .option-client-email,
            .option-client-company-name {
                width: 240px;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
                height: 100%;
            }
        }
    }

    input[type=file]::-webkit-file-upload-button {
        /* chromes and blink button */
        cursor: pointer;
    }

    .preview-input-placeholder::placeholder {
        font-size: 10px;
    }

    .image-preview {
        height: 28px;
        width: 28px;
    }

    .preview-quote-img-preview-div:hover .preview-img-delete-icon {
        display: block;
    }

    .btn-fill {
        &:disabled {
            cursor: no-drop !important;
            pointer-events: all;
        }
    }

    .refrence-text,
    .due-text,
    .issued-text {
        width: 100px;
    }

    .preview-invoice-info {
        margin-left: 4px;
        max-width: 80px;
        font-weight: 500;
    }

    .width-100 {
        width: 100px;
    }

    .amount-th {
        width: 200px;
    }

    .preview-amount-th {
        width: 170px !important;
        max-width: 170px !important;
    }

    .paid-status,
    .read-status,
    .cancelled-status {
        border-radius: 2px !important;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 10px;
    }

    .cancelled-status {
        background-color: var(--declined-status-badge) !important;
    }

    .paid-status {
        background-color: var(--approved-status-badge);
    }

    .read-status {
        background-color: var(--read-status-badge);
    }

    .garment-th {
        width: 550px;
    }

    .tax-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
        max-width: 200px !important;
        text-align: start;


        @media screen and (max-width: 576px) {
            width: 150px;
        }

    }

    .aler-message {
        border-radius: unset !important;
        border: none !important;
        margin: 0 144px;

        @media screen and (max-width:767.99px) {
            margin: 0 25px;
        }

        @media screen and (min-width:767.99px) and (max-width:991.99px) {
            margin: 0 44px;
        }

        @media screen and (min-width:991.99px) and (max-width:1023.99px) {
            margin: 0 75px;
        }
    }

    .invoice-details {
        color: var(--bs-gray-500);
        font-size: 11px;
    }

    .accordion-body,
    .accordion-item:first-of-type .accordion-button {
        padding: 16px;
    }

    .accordion-item,
    .accordion-button {
        border-radius: 10px !important;
    }

    .accordion-body {
        .payment_method_title {

            @media screen and (max-width: 576px) {
                width: 69px;
            }
        }

        .net_amount_title {
            width: 116px;

            @media screen and (max-width: 576px) {
                width: 55px;
            }
        }
    }

    .span-round {
        height: 7px;
        width: 7px;
        display: inline-block;
        background: var(--bs-gray-500);
        border-radius: 50%;
        margin: 0 6px;
    }

    .invoice-name-description {
        max-width: 280px;

        .invoice-data-container {
            width: 450px !important;
            max-width: 460px !important;

            @media screen and (max-width: 1290px) {
                width: 350px !important;
            }

            @media screen and (max-width: 1055px) {
                width: 290px !important;
            }

            @media screen and (max-width: 768px) {
                width: 300px !important;
            }

            @media screen and (max-width: 576px) {
                width: 200px !important;
                max-width: 200px !important;
            }

            .invoice-info {
                width: 450px;
                max-width: 460px !important;
                white-space: break-spaces;

                @media screen and (max-width: 1290px) {
                    width: 330px !important;
                }

                @media screen and (max-width: 1055px) {
                    width: 270px !important;
                }

                @media screen and (max-width: 768px) {
                    width: 300px !important;
                }

                @media screen and (max-width: 576px) {
                    width: 200px !important;
                    max-width: 200px;
                }
            }
        }
    }

    .invoice-item_description {
        color: black;
    }

    .date-selector-title {
        @media screen and (max-width: 576px) {
            width: 55px !important;
        }
    }

    .selected-client-name-container {
        width: 241px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 241px !important;

        .selected-client-info {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .name-font-weight {
        font-weight: 700;
    }

    .email-break {
        word-break: break-all;
    }

    .gray-text {
        color: var(--bs-dark-border-subtle);
        font-weight: 600;
        font-size: 14px;
    }

    .action-dropdown {
        width: 27px;
        border-radius: 4px;
    }

    .dropdown-icon {
        color: var(--primary-green);
    }

    .total-value {
        font-size: 16px;

        @media screen and (max-width:767.99px) {
            font-size: 14px;
        }
    }

    @media screen and (max-width:766.99px) {
        .desktop-statusbar {
            display: none !important;
        }
    }

    @media screen and (min-width:766.99px) {
        .mobile-statusbar {
            display: none !important;
        }
    }

    .preview-quote-card {
        margin: 0 120px;

        @media screen and (max-width:767.99px) {
            margin: 0 0px;
        }

        @media screen and (min-width:767.99px) and (max-width:991.99px) {
            margin: 0 20px;
        }

        @media screen and (min-width:991.99px) and (max-width:1023.99px) {
            margin: 0 40px;
        }


    }

    .back-arrow {
        @media screen and (min-width:1023.99px) {
            position: absolute;
            left: 11px;
        }
    }

    .animation-container{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 150px; 
        margin-right: 150px;

        .animation_card{
            background-color: transparent !important;
            border: 2px solid #e5e7ed ;
            border-radius: 12px;

            .animation_card_body{
                padding: 50px !important;

                @media screen and (max-width: 820px) {
                    padding: 35px !important;
                }

                @media screen and (max-width: 576px) {
                    padding: 30px !important;
                }
            }
        }

        @media screen and (max-width: 820px) {
            margin-left: 100px;
            margin-right: 100px;
        }

        @media screen and (max-width: 600px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .payment_status_image{
        max-width: 110px;
    }

    .client_detail{
        width: 300px;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
    }
}

.noinvoice_container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-gray);
    height: 100vh;
}

.noinvoice_container i {
    position: relative;
    top: 2px;
}

.subtotal-container {
    width: 300px;
    border-bottom: 3px solid var(--primary-gray);

    @media screen and (max-width: 576px) {
        width: 200px;
    }
}

.total-container {
    width: 300px;

    @media screen and (max-width: 576px) {
        width: 200px;
    }
}

.table-th {
    background-color: var(--secondary-light-green);
}