@import '../../../../assets/scss/custom/components/helperFontsize';

.add-edit-customer-modal {

    @media (max-width: 1023.99px) {

        .customer-detail-section,
        .shop-mandatory-detail,
        .shop-city-col,
        .shop-country-col {
            padding-right: 0 !important;
        }
    }

    .customer-detail-section {
        .password-field-padding {
            @media (min-width: 1023.99px) {
                padding-right: 3.5rem !important;
            }
        }

        // info icon
        .register-info-icon {
            font-size: $font-size-14;
            top: 2px;
            left: 2px;
            cursor: pointer;

            // info tooltip
            .info_tooltip {
                font-size: $font-size-12;
                position: absolute;
                background: var(--primary-black) !important;
                box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
                border-radius: 4px;
                white-space: nowrap;
                pointer-events: none;
                transition: 0s;
                display: none;
                word-wrap: break-word;
                white-space: normal;
                font-family: var(--bs-body-font-family);
            }
        }
    }

    @media (min-width: 610px) {
        width: 70% !important;
        max-width: 70% !important;
    }

    .register-info-icon:hover {
        .info_tooltip {
            top: 19px !important;
            z-index: 9;
            display: block;
            pointer-events: auto;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
            right: -338px !important;
            color: var(--primary-white);
            width: 431px !important;
        }

        @media (min-width: 260.98px) and (max-width: 340px) {
            .info_tooltip {
                // right: -139px !important;
                right: -141px !important;
                width: 238px !important;
            }
        }

        @media (min-width: 340.98px) and (max-width: 361px) {
            .info_tooltip {
                top: 17px !important;
                right: -235px !important;
                width: 344px !important;
            }
        }

        @media (min-width: 361.98px) and (max-width: 510px) {
            .info_tooltip {
                // right: -242px !important;
                right: -255px !important;
                width: 349px !important;
            }
        }
    }

    .right-5px {
        right: 5px !important;
    }

    // password eye icon
    .login-eye {
        transform: translate(-50%);
        right: 27px;
        top: 34.93px;
        position: absolute;
        cursor: pointer;
        font-size: $font-size-16;
    }

    // drag and drop icon
    .dropzone {
        border: 2px dashed var(--secondary-white) !important;
        border-radius: 6px !important;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (min-width: 1200px) {
            .display-4 {
                font-size: 2.5rem !important;
            }
        }
    }

    .dropzone-previews {
        display: grid;
        border: 2px solid var(--secondary-white);

        .height-6rem {
            height: 5rem !important;
        }

        .profilePicName {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .invalid-file {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: var(--bs-form-invalid-color);
    }

    // remove arrow from input type number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    textarea {
        resize: vertical !important;
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .add-text-button {
        color: var(--primary-green);
        font-weight: bold;
        border: none !important;

        &:hover {
            color: var(--secondary-green);
        }
    }

    .notes-info-icon {
        top: 3px;
        margin-left: 5px;
    }

    .quotes_info_tooltip {
        display: none
    }

    .notes-info-icon:hover .quotes_info_tooltip {
        display: block;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: 13px;
        z-index: 1000;
        max-width: 200px;
        min-width: 200px;
        white-space: normal;
        overflow-wrap: break-word !important;
        position: absolute;
        font-family: var(--bs-body-font-family);
        font-size: normal;
        left: -50px;
        margin-top: 7px;
        padding: 5px 10px;
        line-height: 1.5;
    }

    .preview-logo-img {
        width: 100px !important;
        max-width: 100px !important;
        height: auto !important;
        max-height: 70px !important;
    }

    .image-error-msg {
        width: 80%;
    }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
textarea:-internal-autofill-selected,
textarea:autofill {
    -webkit-text-fill-color: var(--bs-body-color) !important;
    transition: background-color 5000s ease-in-out 0s;
}