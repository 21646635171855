.status-progress-bar.progress-bar {

    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px 0;
    min-width: 23%;
    overflow: visible !important;

    @media screen and (max-width:766.99px) {
        min-width: 75%;
    }


    .progress-line {
        flex: 1;
        height: 2px;
        background-color: var(--primary-gray);
        position: relative;
        z-index: 1;
    }

    .progress-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--primary-gray);
        border: 2px solid var(--primary-white);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
    }

    .progress-label {
        font-size: 12px;
        text-align: center;
        color: var(--primary-black);
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        background-color: transparent !important;
    }

    .done {
        background-color: var(--secondary-green);
        border-color: var(--secondary-green);
        opacity: 0.5;
        color: var(--secondary-green);
    }

    .current {
        background-color: var(--secondary-green);
        border-color: var(--secondary-green);
        color: var(--secondary-green);
    }

    .remaining {
        background-color: var(--primary-gray);
        border-color: var(--primary-gray);
        color: var(--primary-gray);
    }

    .progress-circle.remaining {
        background-color: transparent;
    }
}