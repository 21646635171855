@import '../../../../assets/scss/custom/components/helperFontsize';
.reset-password-page {
    height: 100vh;
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;

    .header-text {
        color: var(--primary-green);
    }

    .bg-primary-subtle {
        background-color: var(--secondary-green) !important;
    }

    .form-horizontal {

        // remove arrow from input type number
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            appearance: textfield;
            -moz-appearance: textfield;
        }

        // info icon
        .info-icon {
            font-size: $font-size-14;
            top: 2px;
            left: 2px;
            cursor: pointer;
        }

        // info tooltip
        .info_tooltip {
            top: 20px !important;
            font-size: $font-size-12;
            position: absolute;
            background: var(--primary-black) !important;
            box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
            padding: 3px 6px !important;
            border-radius: 4px;
            white-space: nowrap;
            pointer-events: none;
            transition: 0s;
            display: none;
            word-wrap: break-word;
            white-space: normal;
            font-family: var(--bs-body-font-family);
        }

        .info-icon:hover .info_tooltip {
            z-index: 9;
            display: block;
            pointer-events: auto;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
            right: -265px !important;
            color: var(--primary-white);
            width: 384px !important;
        }

        @media (min-width: 260.98px) and (max-width: 340px) {
            .info-icon:hover .info_tooltip {
                right: -97px !important;
                width: 238px !important;
            }
        }

        @media (min-width: 340.98px) and (max-width: 510px) {
            .info-icon:hover .info_tooltip {
                right: -164px !important;
                width: 304px !important;
            }
        }

        // password eye icon
        .login-eye {
            transform: translate(-50%);
            right: 27px;
            top: 34.93px;
            position: absolute;
            cursor: pointer;
            font-size: $font-size-16;
        }

        .right-5px {
            right: 5px !important;
        }

        // cursor not allowed on disable btn
        .btn:disabled {
            cursor: not-allowed !important;
            pointer-events: unset !important;
            background-color: var(--primary-green);
        }
    }

    .logo-img {
        height: 100px !important;

        @media screen and (min-width:607px) and (max-width:750px) {
            height: 120px !important;
        }

        @media screen and (min-width:751px) and (max-width:984px) {
            height: 135px !important;
        }
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }
}