.info-icon-container {
    background-color: var(--primary-light-yellow) !important;

    .info-icon {
        color: var(--primary-yellow) !important;
    }
}

.auth-icon-container {
    background-color: #f46a6a45 !important ;

    .auth-icon {
        color: #e14d4d !important;
    }
}