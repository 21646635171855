.subscription-modal {
    .modal-content {

        .icon-sub-container {
            background-color: var(--secondary-light-green) !important;
            align-items: center;
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;

            .calendor-icon {
                font-size: 24px;
            }
        }

        .email-anchor{
            color: var(--primary-green) !important;

            &:hover{
                border-bottom: 1px solid  var(--primary-green);
            }
        }
    }

}