.add-card-modal {
    .modal-content {
        padding: 16px;
    }

    .duration.selected {
        border: 2px solid var(--primary-yellow) !important;
        background-color: var(--primary-light-yellow) !important;
        color: black !important;
    }

    .error-message {
        color: var(--bs-form-invalid-color);
        font-size: 80%;
    }

    .payable-amountInfo {
        color: var(--secondary-email-text);
    }

    .card-icon-container {
        border-right: 2px solid var(--primary-gray);

        .card_logo {
            height: 20px;
            margin-right: 20px;

            @media screen and (max-width: 576px) {
                margin-right: 10px;
            }
        }
    }

    .secure-payment{
        font-size: 12px;

        @media screen and (max-width: 576px) {
            font-size: 10px;
        }
    }

    .payment-lock {
        top: 4px;

        @media screen and (max-width: 576px) {
            top: 15px;
        }
    }

}

.flatpickr-months {
    .flatpickr-month {
        height: 45px !important;
    }
}

.flatpickr-monthSelect-month.selected {
    background-color: var(--secondary-green) !important;
}