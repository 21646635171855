@import '../../../../assets//scss/custom/components/helperFontsize';

.register-page {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;

    .tab-space {
        display: none;
    }

    .bg-primary-subtle {
        border-top-right-radius: 0.25rem !important;
        border-top-left-radius: 0.25rem !important;

        .header-text {
            color: var(--primary-green);
        }

        .logo-img {
            border-top-right-radius: 0.25rem !important;
        }
    }

    @media (min-width: 819.98px) and (max-width: 913.99px) {
        height: 100vh !important;
    }

    .wizard {
        border-top: 1px solid var(--primary-white) !important;

        @media (min-width: 1199.98px) {
            border-top: 1px solid var(--secondary-white);
        }

        // step
        .steps {
            >ul {
                display: flex;
                flex-wrap: wrap;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;

                >a,
                >li {
                    flex-basis: 0;
                    flex-grow: 1;
                }

                >li {
                    width: 100%;

                    a {
                        display: block;
                        padding: .5rem 1rem;
                        color: var(--primary-black);
                        font-weight: 400;
                        border-bottom: 4px solid transparent;
                    }
                }

                .current-info {
                    position: absolute;
                    left: -999em;
                }
            }

            .number {
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 27px;

                border: 2px solid var(--primary-black);
                color: var(--primary-black);
                text-align: center;
                border-radius: 50%;
                margin-right: .5rem;
            }

            .current {

                a,
                a:active,
                a:hover {
                    color: var(--secondary-green);
                    border-bottom: 4px solid var(--secondary-green);

                    .number {
                        background-color: var(--secondary-green);
                        border: 2px solid var(--secondary-green);
                        color: var(--primary-white);
                    }
                }
            }
        }

        // content
        >.content {
            background-color: transparent;
            padding: 14px;
            margin-top: 0;
            border-radius: 0;
            min-height: 150px;

            >.title {
                position: absolute;
                left: -999em;
            }

            >.body {
                width: 100%;
                height: 100%;
                padding: 14px 0 0;
                position: static
            }
        }

        // actions

        >.actions {
            position: relative;
            display: block;
            text-align: right;
            width: 100%;
            padding: 0 14px;

            .previous.disabled {
                visibility: hidden;
            }

            >ul {
                display: block;
                text-align: right;
                padding-left: 0;

                >li {
                    display: inline-block;
                    margin: 0 0.5em;

                }
            }

            .btn-fill.disabled {
                background-color: var(--primary-green);
                border-radius: 4px;
                color: var(--primary-white);
                padding: 8px 15px;
                opacity: .65;
                cursor: not-allowed;
                border: 1px solid var(--primary-green);
            }
        }

        .form-horizontal {

            // info icon
            .register-info-icon {
                font-size: $font-size-14;
                top: 2px;
                left: 2px;
                cursor: pointer;

                // info tooltip
                .info_tooltip {
                    font-size: $font-size-12;
                    position: absolute;
                    background: var(--primary-black) !important;
                    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
                    border-radius: 4px;
                    white-space: nowrap;
                    pointer-events: none;
                    transition: 0s;
                    display: none;
                    word-wrap: break-word;
                    white-space: normal;
                    font-family: var(--bs-body-font-family);
                }
            }

            .register-info-icon:hover {
                .info_tooltip {
                    top: 19px !important;
                    z-index: 9;
                    display: block;
                    pointer-events: auto;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
                    right: -338px !important;
                    color: var(--primary-white);
                    width: 431px !important;
                }

                @media (min-width: 260.98px) and (max-width: 340px) {
                    .info_tooltip {
                        right: -139px !important;
                        width: 238px !important;
                    }

                    .tab-space {
                        display: block !important;
                    }
                }

                @media (min-width: 340.98px) and (max-width: 361px) {
                    .info_tooltip {
                        top: 17px !important;
                        right: -235px !important;
                        width: 344px !important;
                    }

                    .tab-space {
                        display: block !important;
                    }
                }

                @media (min-width: 361.98px) and (max-width: 510px) {
                    .info_tooltip {
                        right: -242px !important;
                        width: 349px !important;
                    }
                }
            }

            .right-5px {
                right: 5px !important;
            }

            // password eye icon
            .login-eye {
                transform: translate(-50%);
                right: 27px;
                top: 34.93px;
                position: absolute;
                cursor: pointer;
                font-size: $font-size-16;
            }
        }

        .shop-details-form {
            @media (min-width: 260.98px) and (max-width: 340px) {
                .preview-logo-img {
                    height: 2rem !important;
                    width: 2rem !important;
                }
            }

            textarea {
                resize: vertical !important;
            }

            .dropzone-previews {
                border: 2px solid var(--secondary-white);
            }

            // drag and drop icon
            .dropzone {
                border: 2px dashed var(--secondary-white);
                border-radius: 6px;
                cursor: pointer;

                @media (min-width: 1200px) {
                    .display-4 {
                        font-size: 2.5rem !important;
                    }
                }
            }

            .invalid-file {
                width: 100%;
                margin-top: 0.25rem;
                font-size: 80%;
                color: var(--bs-form-invalid-color);
            }

            // remove arrow from input type number
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                appearance: textfield;
                -moz-appearance: textfield;
            }
        }

    }

    .notes-info-icon {
        top: 3px;
        margin-left: 5px;
    }

    .quotes_info_tooltip {
        display: none
    }

    .notes-info-icon:hover .quotes_info_tooltip {
        display: block;
        z-index: 1000000000;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: 13px;
        max-width: 200px;
        min-width: 200px;
        white-space: normal;
        overflow-wrap: break-word !important;
        position: absolute;
        font-family: var(--bs-body-font-family);
        font-size: normal;
        left: -50px;
        margin-top: 7px;
        padding: 5px 10px;
        line-height: 1.5;
    }

    .btn-save {
        background-color: var(--secondary-green);
    }

    .add-text-button {
        color: var(--primary-green) !important;
        font-weight: bold;
        border: none !important;

        &:hover {
            color: var(--secondary-green) !important;
        }
    }

    .logo-img {
        height: 100px !important;

        @media screen and (min-width:607px) and (max-width:750px) {
            height: 120px !important;
        }

        @media screen and (min-width:751px) and (max-width:984px) {
            height: 135px !important;
        }
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .back-login-link {
        margin-top: 2px;
    }

    .back-to-login:hover .back-login-link {
        text-decoration: underline !important;
        border-bottom: var(--secondary-green);
    }

    .header-tab-ul {
        background-color: var(--primary-light-gray);
    }

    @media (min-width: 260.98px) and (max-width: 340px) {

        .tab-space {
            display: block !important;
        }
    }

    @media (min-width: 340.98px) and (max-width: 376px) {

        .tab-space {
            display: block !important;
        }
    }

    .image-error-msg {
        width: 80%;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
textarea:-internal-autofill-selected,
textarea:autofill {
    -webkit-text-fill-color: var(--bs-body-color) !important;
    transition: background-color 5000s ease-in-out 0s;
}