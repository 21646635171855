.landing-page {
    height: 100vh;

    .header-btn {
        border: 1px solid transparent !important;
        background: transparent !important;
    }

    .landing-page-logo {
        height: 50px !important;
    }

    .title-text {
        width: 47%;
        color: var(--primary-green);
        font-size: 40px !important;
        font-weight: 800 !important;
    }

    .title-text-span {
        color: var(--secondary-green);
    }

    .detail-text {
        font-size: 14px;
        width: 47%;
        color: var(--secondary-email-text);
    }

    .color-secondary {
        color: var(--secondary-green);
    }

    .main-img {
        height: 38vw;
        margin-top: -25px;
        width: -webkit-fill-available;
    }

    .landing-page-header {
        padding-left: 38px;
        padding-right: 38px;
    }

    .landing-page-link {
        border-bottom: 1px solid transparent;
    }

    .landing-page-link:hover {
        color: var(--secondary-green);
        border-bottom: 1px solid var(--secondary-green);
    }

    .footer-link {
        color: var(--primary-white);
    }

    .logo-sm {
        display: none;
    }

    .horizontal-line {
        width: 100%;
        border-bottom: 3px solid var(--light-yelow);
        line-height: 0.1em;
        margin-top: -19px;
    }

    .horizontal-line-text {
        background: var(--light-yelow);
        padding: 2px 17px;
        color: var(--primary-yellow);
        margin-left: 8%;
        border-radius: 5px;
        font-weight: 600;
        font-size: 15px;
        padding: 2px 17px 3px 17px;
    }

    .features-containter {
        margin-left: 8%;
        margin-right: 8%;
        margin-top: 20vh;
        margin-bottom: 19vh;
    }

    .last-sec-margin {
        margin-top: 15vh;
        margin-left: 8%;
        margin-right: 8%;
        // margin-bottom: 19vh;
        margin-bottom: 12vh;
    }

    .features-right-img,
    .features-left-img {
        width: 39vw;
    }

    .features-img-div {
        background: var(--secondary-light-green);
        border-radius: 20px;
    }

    .img-fluid {
        border-radius: 16px;
    }

    .margin-top-18 {
        margin-top: 18vh;
    }

    .pricing-sec-title {
        width: 95%;
        margin-bottom: 40px;
    }

    .pricing-card {
        width: 47%;
        border: 1px solid var(--bs-gray-400);
        border-radius: 14px;
        background: transparent;

        .card-body {
            div {
                div {
                    min-height: 36px;
                }
            }
        }
    }

    .type-span {
        font-weight: 600;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
    }

    .crown-img {
        width: 10%;
    }

    .price-div {
        border-bottom: 1px solid var(--bs-gray-400);
    }

    .check-img {
        width: 5%;
    }

    .btn-div {
        margin-top: 68px;
    }

    .btn-price-outline {
        color: var(--secondary-green);
        border: 1px solid var(--secondary-green) !important;
        width: 86%;
    }

    .btn-price-outline:hover,
    .btn-price-outline:focus-visible {
        background-color: var(--secondary-green) !important;
        color: var(--primary-white) !important;
    }

    .btn-price-fill {
        width: 86%;
        background-color: var(--secondary-green) !important;
        color: var(--primary-white) !important;
    }

    .btn-price-fill:hover,
    .btn-price-fill:focus-visible {
        background-color: var(--secondary-dark-green) !important;
        color: var(--primary-white) !important;
    }

    .pricing-background-secondary-green {
        background-color: var(--secondary-light-green);
        border-radius: 14px;
    }

    .last-sec-desc-text {
        font-size: 16px;
    }

    .footer-section {
        background-color: var(--primary-green);
        padding-left: 8%;
        padding-right: 8%;
    }

    .footer-logo-img {
        width: 36%;
        margin-left: -35px;
    }

    .footer-icon-img {
        // width: 5%;
        width: 28px;
    }

    .footer-icon-img:hover {
        width: 30px;
    }

    .footer-img-sec {
        border-bottom: 1px solid var(--landing-page-footer-border);
    }

    .copyright-text {
        color: var(--bs-gray-500);
    }

    .icons-span {
        height: 32px;
    }

    @media screen and (min-width:1620.99px) {
        .detail-text {
            font-size: 15px;
            width: 43%;
        }

        .title-text {
            width: 83%;
        }

        .horizontal-line-text {
            font-size: 16px;
        }

        .features-title {
            font-size: 30px !important;
        }

        .pricing-card {
            width: 35%;
        }

        .fb-icon {
            position: absolute;
            right: 4vw;
        }
    }

    @media screen and (min-width:1400.99px) and (max-width:1619.99px) {
        .pricing-card {
            width: 41%;
        }

        .last-sec-margin {
            margin-top: 15vh;
        }

    }

    @media screen and (min-width:1025.99px) {
        .features-title {
            font-size: 30px !important;
        }

        .fb-icon {
            position: absolute;
            right: 4vw;
        }
    }

    @media screen and (min-width:1023.99px) and (max-width:1025px) {
        .pricing-card {
            width: 57% !important;
        }
    }

    // tablet device
    @media screen and (min-width:751px) and (max-width:1025px) {
        .landing-page-logo {
            height: 44px !important;
        }

        .main-img {
            margin-top: 9px;
        }

        .detail-text {
            font-size: 12px;
            width: 60%;
            color: var(--secondary-email-text);
        }

        .title-text {
            width: 60%;
            color: var(--primary-green);
            font-size: 32px !important;
            font-weight: 800 !important;
        }

        .horizontal-line-text {
            font-size: 13px;
        }

        .logo-sm {
            display: none;
        }

        .horizontal-line {
            margin-top: 2px;
        }

        .margin-top-18 {
            margin-top: 7vh;
        }

        .features-containter {
            margin-top: 10vh;
            margin-bottom: 9vh;
        }

        .pricing-sec-title {
            width: 90%;
        }

        .pricing-card {
            width: 80%;
        }

        .last-sec-margin {
            margin-top: 10vh;
            margin-bottom: 8vh;
        }

        .footer-logo-img {
            width: 45%;
        }

        .fb-icon {
            position: absolute;
            right: 7vw;
        }
    }

    // mobile device
    @media screen and (max-width:749.99px) {
        .main-img {
            margin-top: 3px;
        }

        .detail-text {
            font-size: 9px;
            width: 90%;
        }

        .title-text {
            width: 90%;
            font-size: 22px !important;
        }

        .horizontal-line-text {
            font-size: 10px;
        }

        .logo-lg {
            display: none;
        }

        .logo-sm {
            display: block !important;
        }

        .landing-page-header {
            padding-left: 28px;
            padding-right: 28px;
        }

        .horizontal-line {
            margin-top: 13px;
        }

        .margin-top-18 {
            margin-top: 6vh;
        }

        .features-right-img,
        .features-left-img {
            width: 100%;
        }

        .features-containter {
            margin-top: 7vh;
            margin-bottom: 7vh;
        }

        .pricing-card {
            width: 80%;
        }

        .last-sec-margin {
            margin-bottom: 8vh;
            margin-top: 9vh;
        }

        .footer-logo-img {
            width: 36%;
            margin-left: 0;
        }
    }

    .card-radio-duration{
        padding-inline: 20px;
        border-bottom: 2px solid #eff2f7 ;
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
        border-radius: 0px !important;
    }
    
    .card-radio {
        border-radius: 4px;
        border: 2px solid #eff2f7;
        padding: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
        &:hover {
            cursor: pointer;
        }
    
        .plan-descrition {
            white-space: break-spaces;
        }
    }
    
    .card-radio-label {
        display: block;
    }
    
    .type-options-switch {
        justify-content: center;
        margin-bottom: 30px;

        .type-option-card {
            background-color: transparent;
        }
    }
    
    .feature_list_container {
        min-height: 130px !important;
    }

    .card-radio-input {
        display: none;
    
        &:checked+.card-radio {
            border: 2px solid var(--primary-yellow);
            border-radius: 4px;
        }
    
        &:checked+.card-radio-duration{
            border-radius: 0px !important;
            border-top: 0px !important;
            border-left: 0px !important;
            border-right: 0px !important;
            border-bottom: 2px solid var(--secondary-green) !important;
            font-weight: bold;
        }
    }

    .card-grid .blinking-animation {
        height: fit-content;
        animation: borderBlink 1.5s infinite ease-in-out;
        border-radius: 5px;
        border: 1px solid var(--primary-yellow);
    }    
    
    /* Blinking border animation */
    @keyframes borderBlink {
        0% {
            box-shadow: 0 0 3pxvar(--primary-yellow), 0 0 6px var(--primary-yellow);
        }
        50% {
            box-shadow: 0 0 3px var(--primary-yellow), 0 0 6px var(--primary-yellow);
        }
        100% {
            box-shadow: 0 0 3px var(--primary-yellow), 0 0 6px var(--primary-yellow);
        }
    }    
}