.invoice-list-page {

    .invoice-list {
        table {
            width: 100% !important;
            position: relative;
            z-index: 0;

            .sticky-header {
                position: sticky;
                top: 0;
                z-index: 10;
            }

            .invoice-notes-container {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: var(--secondary-green) !important;
                }

                .notes-icon {
                    color: var(--primary-yellow);
                }

                &:hover .notes-icon {
                    color: var(--secondary-green);
                }
            }

            .action-dropdown {
                height: 20px !important;
                width: 20px !important;
                border-radius: 50% !important;
                display: flex;
                align-items: center;

                &:hover {
                    color: var(--secondary-green) !important;
                }

                .action-icon {
                    color: var(--primary-yellow);
                }

                &:hover .action-icon {
                    color: var(--secondary-green) !important;
                }
            }
        }

        .sort-icons {
            .sort-icon-height {
                height: 6px;
            }
        }

        @media (max-height: 601px) {
            .table-responsive {
                height: 60vh !important;
            }
        }

        @media (min-height: 602px) and (max-height: 739px) {
            .table-responsive {
                height: 63vh !important;
            }
        }

        @media (min-height: 740px) and (max-height: 1022px) {
            .table-responsive {
                height: 64vh !important;
            }
        }

        @media (min-height: 1023px) {
            .table-responsive {
                height: 80vh !important;
            }
        }

        @media (max-width: 765px) and (max-height: 741px) {
            .table-responsive {
                height: 58vh !important;
            }
        }

        @media (max-width: 765px) and (min-height: 742px) and (max-height: 843px) {
            .table-responsive {
                height: 54vh !important;
            }
        }

        @media (max-width: 765px) and (min-height: 843px) {
            .table-responsive {
                height: 65vh !important;
            }
        }

        .dropdown-menu-text {
            top: 1px;
            position: relative;
            left: 2px;
        }

        // no data found
        .nouser_avaibale_container {
            height: 49vh;
            justify-content: center;
            display: flex;
            align-items: center;
            color: var(--primary-gray) !important;
        }

        // spinner
        .loader {
            position: absolute;
            top: 50%;
            right: 50%;
            color: var(--primary-black) !important;

            .spinner-border.text-light {
                color: var(--primary-black) !important;
            }
        }

        .expired-date-badge {
            font-size: 13px;
            background-color: var(--primary-white) !important;
            padding: 0 !important;
        }

        .status-badge {
            font-size: 9px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px 0px !important;
            max-width: 90px;
            min-width: 60px;
        }

        @media (max-width: 600px) {
            .customer_ellipsis:hover::after {
                font-size: 10px !important;
            }

            .quote-date-div {
                width: 75px;
            }
        }

        // for info tooltip
        .info-tooltip {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .info-tooltip:hover {
            overflow: visible !important;
        }

        .info-tooltip:hover::after {
            font-family: var(--bs-body-font-family);
            font-style: normal;
            content: attr(data-title);
            position: absolute;
            right: 0px;
            top: 60px;
            margin-top: -34px;
            padding: 5px 10px;
            border-radius: 5px;
            color: var(--primary-white);
            background-color: var(--primary-black);
            border-radius: 5px;
            font-size: 13px;
            z-index: 10;
            max-width: 175px;
            min-width: 84px;
            white-space: normal;
            overflow-wrap: break-word !important;
        }

        .notes-icon:hover::after {
            right: 0 !important;
            left: unset !important;
        }

        /* css for displaying ellipsis when name is long */
        .customer_ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        .customer_ellipsis:hover::after {
            content: attr(data-title);
            position: absolute;
            left: 0px;
            top: 65px;
            margin-top: -34px;
            padding: 5px 10px;
            border-radius: 5px;
            color: var(--primary-white);
            background-color: var(--primary-black);
            border-radius: 5px;
            font-size: 13px;
            z-index: 10;
            max-width: 175px;
            min-width: auto;
            white-space: normal;
            overflow-wrap: break-word !important;
        }

        // table cell col width
        .job-name {
            width: 198px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 100%;
            display: block;
        }

        .col-job_name {
            width: 25%;
        }

        .company-name-email {
            width: 80px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 100%;
        }

        .gray-email {
            color: var(--secondary-email-text);
            font-size: 12px;
        }

        .col-invoice_total {
            width: 10%;
        }

        .col-quote_status {
            width: 12% !important;
        }

        .col-quote_number {
            width: 12%;
        }

        .col-due_date,
        .col-issued_on {
            width: 16%;
            white-space: nowrap !important;
        }

        .col-Action {
            width: 8%;
            text-align: center;
        }

        .quote-id-tooltip {
            display: none;
        }

        .quote-id-info:hover .quote-id-tooltip {
            position: absolute;
            min-width: max-content;
            background: var(--primary-black) !important;
            transition: 0s;
            word-wrap: break-word;
            white-space: normal;
            font-family: var(--bs-body-font-family);
            top: 19px !important;
            display: block;
            pointer-events: auto;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
            right: -77px !important;
            color: var(--primary-white);
            max-width: 200px !important;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 13px;
            z-index: 10;
            line-height: 1.5;
        }

        .shop-email-tooltip:hover::after {
            top: 89px !important;
        }

        .company-name-tooltip:hover::after {
            top: 70px !important;
        }

        input[type="search"]::-webkit-search-cancel-button {
            padding-bottom: 1px;
        }

        .list-search {
            padding-right: 28px !important;
        }

        @media (max-width: 1024px) {

            .company-name-email {
                width: 80px !important;
            }

            .job-name,
            .width-100 {
                width: 100px !important;
            }

            .table-body-font,
            .list-search,
            .list-search::placeholder {
                font-size: 11px !important;
                border-radius: 4px !important;
            }

            .list-search {
                width: 100% !important;
            }
        }

        input[type="search"]::-webkit-search-decoration:hover,
        input[type="search"]::-webkit-search-cancel-button:hover {
            cursor: pointer;
        }

        .form-control {
            border: 2px solid var(--secondary-white) !important;
        }

        .dropdown-menu-icon {
            line-height: 1 !important;
            margin-top: 2px !important;
            width: 17px;
        }

        .dropdown-menu-icon::before {
            line-height: 1 !important;
        }

        .select-section {

            @media (max-width: 1022px) {
                font-size: 11px;

                .form-label {
                    font-size: 11px !important;
                    font-weight: 600;
                }
            }

            .css-b62m3t-container {
                width: 100%;
            }
        }

        .select2-selection {
            .css-13cymwt-control {
                border: 1px solid var(--secondary-white) !important;
                box-shadow: none;
                margin: 0px !important;
            }

            .css-1fdsijx-ValueContainer {
                padding: 0px 12px;
            }
        }

        .css-1nmdiq5-menu {
            background-color: var(--primary-white) !important;
            overflow-y: auto;
            max-height: 300px;
        }

        .badge-span {
            width: 11px;
            height: 11px;
            display: inline-block;
            border: 1px solid var(--primary-yellow) !important;
            background: var(--primary-yellow);
            border-radius: 50%;

            @media (max-width: 392px) {
                width: 18px;
            }

            @media (max-width: 765px) {
                width: 9px;
                height: 9px;
            }
        }

        .badge-indicator {
            font-size: 12px !important;
            background-color: transparent !important;
        }

        .badge-span-color-secondary {
            background-color: var(--secondary-green);
            border-color: var(--secondary-green) !important;
        }

        .mb-2px {
            margin-bottom: 2px;
        }

        .col-indicator {
            padding-left: 0;
            padding-right: 0;
        }

        .search-info-icon {
            right: 14px;
            top: 13px;
        }

        .search-tooltip {
            display: none;
        }

        .search-info-icon:hover {
            .search-tooltip {
                display: block;
                color: var(--primary-white);
                background-color: var(--primary-black);
                border-radius: 5px;
                font-size: 13px;
                z-index: 150;
                max-width: 217px;
                min-width: 217px;
                white-space: normal;
                overflow-wrap: break-word !important;
                position: absolute;
                font-family: var(--bs-body-font-family);
                font-style: normal;
                left: -112px;
                top: 60px;
                margin-top: -34px;
                padding: 5px 10px;
                line-height: 1.5;

                @media (max-width: 765px) {
                    left: -188px;
                }
            }
        }

        .quote-note-info {
            font-size: 18px;
        }
    }

    // for download-csv-button
    .download-csv-btn {
        background-color: var(--primary-white);
        border-radius: 4px;
        color: var(--primary-yellow);
        border: 1px solid var(--primary-yellow);
        font-size: 12px;
        padding: 5px 16px;
    }

    .download-csv-btn:focus {
        box-shadow: none !important;
    }

    .download-csv-btn:hover,
    .download-csv-btn:active {
        background-color: var(--primary-yellow) !important;
        color: var(--primary-white) !important;
        border-color: transparent !important;
    }

    // for breadcrumb
    @media screen and (max-width: 420px) {
        .page-title-invoice-list {
            display: block !important;
        }

        .breadcrumb-invoice-list {
            justify-content: end !important;
        }
    }
}


.download-quote-spinner {
    width: 4rem !important;
    height: 4rem !important;
    top: calc(50% - 83px);
    right: 50%;
    color: var(--secondary-green);
    z-index: 1;

    @media screen and (max-width: 768px) {
        right: 38%;
    }

    @media screen and (min-width: 768px) and (max-width: 819px) {
        right: 42%;
    }
}