//
// _helper.scss
//

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-36 {
    font-size: 36px !important;
}

.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.add-tax-button {
    color: var(--primary-green);
    font-weight: 600;

    &:hover {
        color: var(--secondary-green);
    }
}

// Social

.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 4px);
    display: block;
    border: 2px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
    text-align: center;
    transition: all 0.4s;

    &:hover {
        color: var(--#{$prefix}secondary-color);
        background-color: $gray-200;
    }
}


.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.w-xl {
    min-width: 160px;
}

// cursor pointer
.cursor-pointer {
    cursor: pointer;
}

// alert

.alert-dismissible {
    .btn-close {
        font-size: 10px;
        padding: $alert-padding-y * 1.4 $alert-padding-x;
    }
}

.chartjs-chart {
    max-height: 300px;
}

// buttons

.btn-outline {
    background-color: var(--primary-white) !important;
    border-radius: 4px;
    color: var(--primary-green);
    padding: 8px 15px;
    border: 1px solid var(--primary-green) !important;
}

.btn-outline:hover {
    background-color: var(--primary-green) !important;
    color: var(--primary-white);
    border: 1px solid transparent !important;
}

.btn-fill {
    background-color: var(--primary-green) !important;
    border-radius: 4px;
    color: var(--primary-white) !important;
    padding: 8px 15px;
    border: 1px solid transparent !important;
}

.btn-fill:hover {
    background-color: var(--primary-dark-green) !important;
}

.btn-fill:disabled {
    background-color: var(--primary-green) !important;
    color: var(--primary-white) !important;
    opacity: .65 !important;
    cursor: not-allowed !important;
    border: 1px solid var(--primary-green) !important;
}

// for email word break
.email-break {
    word-break: break-all;
}

// for text color yellow
.text-yellow {
    color: var(--primary-yellow);
}

.color-secondary {
    color: var(--secondary-green);
}

.color-secondary-dark {
    color: var(--secondary-dark-green);
}

.color-primary {
    color: var(--primary-green);
}

.color-primary-white {
    color: var(--primary-white);
}

// for quick tour
.introjs-helperLayer {
    border: 3px solid var(--secondary-green) !important;
    box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

.introjs-button {
    text-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
    background: none !important;
    font: initial !important;
}

.introjs-skipbutton {
    color: var(--primary-yellow) !important;
    font-size: 11px !important;
}

.introjs-skipbutton:hover {
    text-decoration: underline !important;
    border-bottom: var(--primary-yellow);
}

.introjs-nextbutton,
.introjs-prevbutton {
    font-size: 11px !important;
    color: var(--secondary-green) !important;
}

.introjs-nextbutton:hover,
.introjs-prevbutton:hover {
    text-decoration: underline !important;
    border-bottom: var(--secondary-green);
}

.introjs-bullets ul li a {
    background: var(--secondary-green) !important;
}

.introjs-tooltiptext {
    padding: 0 20px;
}

.introjs-tooltipReferenceLayer * {
    font-family: var(--bs-body-font-family) !important;
}

.introjs-tooltip {
    padding: 5px !important;
    min-width: 250px;
    max-width: 100% !important;

    @media screen and (max-width:765.99px) {
        // min-width: 355px;
        min-width: 335px;
        max-width: 100% !important;
    }
}

.introjs-tooltiptext {
    // padding: 5px 8px 0 8px !important;
    padding: 10px 10px 0 10px !important;
    font-size: 12px;
}

.introjs-helperLayer {
    background: transparent;
}

.introjs-helperNumberLayer {
    background: var(--secondary-green) !important;
    border: none !important;
    left: -10px !important;
}

.introjs-overlay {
    opacity: 0 !important;
}

.testHighlight {
    bottom: 0 !important;
    top: auto;
}

// for scroll style
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #d7d7d7;
    border-radius: 10px;
}

// status badge background color
.approved-status {
    background: var(--approved-status-badge) !important;
}

.declined-status {
    background: var(--declined-status-badge) !important;
}

.draft-status {
    background: var(--draft-status-badge) !important;
}

.read-status {
    background: var(--read-status-badge) !important;
}

.sent-status {
    background: var(--sent-status-badge) !important;
}

// table sticky header
.table-sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange,
.css-tr4s17-option:active,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:active,
.flatpickr-day.endRange:hover{
    background-color: var(--secondary-green) !important;
    border-color: var(--secondary-green) !important;
}