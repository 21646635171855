.preview-quote-page {
    font-size: 0.8347rem !important;

    .error-message {
        font-size: 80%;
        color: var(--bs-form-invalid-color);
        width: inherit;
        white-space: break-spaces;
    }

    .width-180 {
        max-width: 180px;
    }

    .preview-quote-page {
        font-size: 0.8347rem !important;

        .error-message {
            font-size: 80%;
            color: var(--bs-form-invalid-color);
            width: inherit;
            white-space: break-spaces;
        }

        .width-180 {
            max-width: 180px;
        }

        .notes-textarea {
            width: 550px;
            max-width: 550px;

            @media screen and (min-width: 768px) and (max-width: 1200px) {
                max-width: 400px;
                width: 400px;
            }

            @media screen and (min-width: 577px) and (max-width: 767.999px) {
                max-width: 300px;
                width: 300px;
            }

            @media screen and (max-width: 576px) {
                max-width: 100%;
                width: 100%;
            }
        }

        @media (max-width: 391px) {
            font-size: 0.6347rem !important;
        }

        .preview-quote-title {
            .shop-logo {
                .preview-quote-shop-logo {
                    height: 60px !important;
                }
            }
        }

        .job_name_input {
            height: 36px;
            max-width: 200px;
        }

        .add-button {
            width: max-content !important;
            max-width: 150px;
            background-color: transparent !important;
            color: var(--secondary-green) !important;
            margin: 5px;
            padding: 5px;
            display: flex;
            align-items: center;
            border: 1px solid var(--secondary-green) !important;
            box-shadow: none !important;


            &:hover {
                color: white !important;
                background-color: var(--secondary-green) !important;
                border: 1px solid var(--secondary-green) !important;
            }
        }

        .preview-quote-table {
            .width-100 {
                width: 100px;
            }


            .width-120 {
                width: 120px;
                max-width: 120px !important;
            }

            .preview-quote-file {
                opacity: 0;
                position: absolute;
                top: 14px;
                left: 12px;
                width: 28px;
                height: 28px;
                cursor: pointer !important;
            }

            .width-106 {
                width: 106px;
                max-width: 210px;
            }

            .width-200 {
                width: 200px;
                max-width: 200px;
            }

            .width-250 {
                width: 250px;
                max-width: 250px !important;
            }

            .width-300 {
                width: 350px;
                max-width: 350px !important;

                @media screen and (min-width:820px) and (max-width: 1025px) {
                    max-width: 300px !important;
                    width: 150px;
                }

                @media screen and (min-width: 576px) and (max-width:820px) {
                    max-width: 200px !important;
                    width: 100px;
                }

                @media screen and (max-width:576px) {
                    max-width: 200px !important;
                    width: 150px;
                }
            }

            .width-350 {
                width: 350px;
                max-width: 350px !important;
            }

            .width-400 {
                width: 400px;
                max-width: 400px !important;
            }

            .width-500 {
                width: 500px;
                max-width: 500px !important;

                @media screen and (min-width: 576px) and (max-width: 820px) {
                    max-width: 200px !important;
                    width: 200px;
                }

                @media screen and (max-width:576px) {
                    max-width: 200px !important;
                    width: 150px;
                }
            }

            @media (max-width: 668px) {

                .table-data,
                .table-th {
                    padding: 0.5rem 0.5rem !important;
                }
            }

            @media (max-width: 391px) {

                .table-data,
                .table-th {
                    padding: 0.5rem 0.4rem !important;
                }
            }
        }

        .table-th {
            background-color: var(--secondary-light-green);
        }

        .preview-quote-spinner {
            width: 4rem;
            height: 4rem;
            top: 50%;
            right: 50%;
            color: var(--secondary-green);
            z-index: 12;
        }

        .preview-img-delete-icon {
            left: 30px;
            bottom: 38px;
            border-radius: 50%;
            background: var(--primary-light-red) !important;
            font-size: 11px !important;
            line-height: 1;
            border-radius: 50%;
            padding: 4px 3px 2px 3px;
            display: none;
        }

        .preview-quote-img-preview-div {
            height: 28px;
            width: 28px;
        }

        .btnimg {
            color: var(--bs-gray);
            background: var(--bs-gray-100);
            pointer-events: none;
            width: 28px;
            height: 28px;
            opacity: 1;
            text-align: center;
            border-radius: 4px;
            padding-top: 1px;
            cursor: pointer;
        }

        input[type=file]::-webkit-file-upload-button {
            /* chromes and blink button */
            cursor: pointer;
        }

        .image-preview {
            height: 28px;
            width: 28px;
        }

        .preview-quote-img-preview-div:hover .preview-img-delete-icon {
            display: block;
        }

        .btn-fill {
            &:disabled {
                cursor: no-drop !important;
                pointer-events: all;
            }
        }

        .input {
            padding: 0px 0px 0px 5px !important;
        }
    }

    .error-message {
        font-size: 80%;
        color: var(--bs-form-invalid-color);
        width: inherit;
        white-space: break-spaces;
    }

    .width-180 {
        max-width: 180px;
    }

    .vertical-align-data {
        vertical-align: middle;
    }

    .notes-textarea {
        width: 550px;
        max-width: 550px;

        @media screen and (min-width: 768px) and (max-width: 1200px) {
            max-width: 400px;
            width: 400px;
        }

        @media screen and (min-width: 577px) and (max-width: 767.999px) {
            max-width: 300px;
            width: 300px;
        }

        @media screen and (max-width: 576px) {
            max-width: 100%;
            width: 100%;
        }
    }

    @media (max-width: 391px) {
        font-size: 0.6347rem !important;
    }

    .preview-quote-title {
        .shop-logo {
            .preview-quote-shop-logo {
                height: 60px !important;
            }
        }
    }

    .job_name_input {
        height: 36px;
        max-width: 200px;
    }

    .add-button {
        width: max-content !important;
        max-width: 150px;
        background-color: transparent !important;
        color: var(--secondary-green) !important;
        margin: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        border: 1px solid var(--secondary-green) !important;
        box-shadow: none !important;


        &:hover {
            color: white !important;
            background-color: var(--secondary-green) !important;
            border: 1px solid var(--secondary-green) !important;
        }
    }

    .table-header-rate {
        width: 200px;
        min-width: 100px !important;

        @media screen and (max-width: 768px) {
            width: 130px;
        }

        @media screen and (max-width:568px) {
            width: 100px;
        }
    }

    .subtotal-container {
        width: 300px;
        border-bottom: 3px solid var(--primary-gray);

        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }

    .total-container {
        width: 300px;

        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }

    .preview-quote-table {
        .width-100 {
            width: 100px;
        }

        .tax_detail_row {
            width: 130px !important;

            @media screen and (max-width: 576px) {
                width: 120px !important;
            }

            .tax-display-quote-items-popup {
                color: var(--primary-gray);
            }

            .tax-display-quote-items-elipsis {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 60px;
                max-width: 60px !important;
                text-align: start;
                color: var(--primary-gray);
            }
        }

        .add-text-button {
            color: var(--primary-green);
            font-weight: bold;
            margin-top: 3px !important;

            &:hover {
                color: var(--secondary-green);
            }
        }

        .width-120 {
            width: 120px;
            max-width: 120px !important;

            @media screen and (max-width: 576px) {
                width: auto !important;
                max-width: 70px !important;
                min-width: 70px !important;
            }

            @media screen and (min-width: 576px) and (max-width: 768px) {
                width: auto !important;
                max-width: 90px !important;
            }
        }

        .preview-quote-file {
            opacity: 0;
            position: absolute;
            top: 14px;
            left: 12px;
            width: 28px;
            height: 28px;
            cursor: pointer !important;
        }

        .width-106 {
            width: 106px;
            max-width: 210px;
        }

        .width-200 {
            width: 200px;
            max-width: 200px;
        }

        .width-250 {
            width: 250px;
            max-width: 250px !important;
        }

        .width-300 {
            width: 350px;
            max-width: 350px !important;

            @media screen and (min-width:820px) and (max-width: 1025px) {
                max-width: 300px !important;
                width: 150px;
            }

            @media screen and (min-width: 576px) and (max-width:820px) {
                max-width: 200px !important;
                width: 100px;
            }

            @media screen and (max-width:576px) {
                max-width: 200px !important;
                width: 150px;
            }
        }

        .width-350 {
            width: 350px;
            max-width: 350px !important;
        }

        .width-400 {
            width: 400px;
            max-width: 400px !important;
        }

        .preview-table-th {
            @media screen and (min-width: 1400px) and (max-width: 1460px) {
                width: 450px !important;
                max-width: 450px !important;
            }
        }

        .width-300 {
            @media screen and (min-width: 1400px) and (max-width: 1460px) {
                width: 320px;
            }
        }

        .width-500 {
            width: 500px;
            max-width: 500px !important;

            @media screen and (min-width: 576px) and (max-width: 820px) {
                max-width: 200px !important;
                width: 200px;
            }

            @media screen and (max-width:576px) {
                max-width: 200px !important;
                width: 150px;
            }
        }

        @media (max-width: 668px) {

            .table-data,
            .table-th {
                padding: 0.5rem 0.5rem !important;
            }
        }

        @media (max-width: 391px) {

            .table-data,
            .table-th {
                padding: 0.5rem 0.4rem !important;
            }
        }
    }

    .table-th {
        background-color: var(--secondary-light-green);
    }

    .preview-quote-spinner {
        width: 4rem;
        height: 4rem;
        top: 50%;
        right: 48%;
        color: var(--secondary-green);

        @media screen and (max-width: 539px) {
            right: 40%;
        }

        @media screen and (min-width: 539px) and (max-width: 819px) {
            right: 44%;
        }
    }

    .preview-input-placeholder::placeholder {
        font-size: 10.7px !important;
    }

    .height-50 {
        height: 50px;
    }

    .height-40 {
        height: 40px;
    }

    .preview-img-delete-icon {
        left: 30px;
        // bottom: 60px;
        top: 30px;
        border-radius: 50%;
        background: var(--primary-light-red) !important;
        font-size: 11px !important;
        line-height: 1;
        border-radius: 50%;
        padding: 4px 3px 2px 3px;
        display: none;
    }

    .preview-quote-img-preview-div {
        height: 28px;
        width: 28px;
    }

    .btnimg {
        color: var(--bs-gray);
        background: var(--bs-gray-100);
        pointer-events: none;
        width: 28px;
        height: 28px;
        opacity: 1;
        text-align: center;
        border-radius: 4px;
        padding-top: 1px;
        cursor: pointer;
    }

    input[type=file]::-webkit-file-upload-button {
        /* chromes and blink button */
        cursor: pointer;
    }

    .image-preview {
        height: 28px;
        width: 28px;
    }

    .preview-quote-img-preview-div:hover .preview-img-delete-icon {
        display: block;
    }

    .btn-fill {
        &:disabled {
            cursor: no-drop !important;
            pointer-events: all;
        }
    }

    .input {
        padding: 0px 0px 0px 5px !important;
    }

    .name-font-weight {
        font-weight: 700;
    }

    .email-break {
        word-break: break-all;
    }

    .gray-text {
        color: var(--bs-dark-border-subtle);
        font-weight: 600;
        font-size: 14px;
    }

    .action-dropdown {
        width: 27px;
        border-radius: 4px;
    }

    .dropdown-icon {
        color: var(--primary-green);
    }

    .total-value {
        font-size: 16px;

        @media screen and (max-width:767.99px) {
            font-size: 14px;
        }
    }

    @media screen and (max-width:766.99px) {
        .desktop-statusbar {
            display: none !important;
        }
    }

    @media screen and (min-width:766.99px) {
        .mobile-statusbar {
            display: none !important;
        }
    }

    .preview-quote-card {
        margin: 0 120px;

        @media screen and (max-width:767.99px) {
            margin: 0 0px;
        }

        @media screen and (min-width:767.99px) and (max-width:991.99px) {
            margin: 0 20px;
        }

        @media screen and (min-width:991.99px) and (max-width:1023.99px) {
            margin: 0 40px;
        }
    }

    .back-arrow {
        @media screen and (min-width:1023.99px) {
            position: absolute;
            // left: 11px;
            left: 16px;
        }
    }

    .check-icon {
        font-size: 30px;
    }

    .success-alert {
        background: var(--secondary-light-green);

        @media screen and (max-width:377.99px) {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .check-icon {
                margin-right: 4px !important;
            }
        }
    }

    .invoice_number_alert {
        background-color: #70ddff2e !important;

        .invoice_number {
            color: #70ddff !important;
            border-color: #70ddff !important;
            font-weight: bold;
        }
    }

    .alert-message {
        border: none !important;
        border-radius: unset !important;

        @media screen and (max-width:767.99px) {
            font-size: 12px;

            .check-icon {
                font-size: 22px;
            }
        }
    }

    .success-alert-width {
        margin: 0 120px;

        @media screen and (min-width: 991.99px) and (max-width: 1023.99px) {
            margin: 0px 40px;
        }

        @media screen and (max-width:767.99px) {
            margin: 0 0px;
        }

        @media screen and (min-width:767.99px) and (max-width:991.99px) {
            margin: 0 20px;
        }
    }

    .selected-recipient-container {
        border: 1px solid transparent;

        &:hover {
            border: 1px solid #ced4da;
        }

        &:hover .choose-recipient-icon {
            display: inline;
        }

        .choose-recipient-icon {
            display: none;
        }
    }

    .choose-a-recipient {
        border: 1px solid var(--primary-gray);
        height: 40px;
        width: 286px;

        .down-icon {
            position: relative;
            top: -5px;
        }

        &:hover {
            border: 1px solid var(--primary-gray);
        }
    }

    .client-search-bar {
        width: 286px;
        margin-left: 15px !important;
    }

    .selected-client-name-container {
        width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px !important;

        .selected-client-info {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

    }

    .selected-client-name-container-preview {
        width: 300px;
        max-width: 300px;
    }

    .to-container {
        width: 300px !important;

        @media screen and (max-width: 600px) {
            padding-right: 15px;
        }
    }

    .description {
        color: var(--secondary-email-text) !important;
    }

    .select-client-dropdown-menu {
        width: 286px;
        margin-left: 15px !important;
        max-height: 260px;
        overflow-x: hidden !important;
        overflow-y: auto !important;

        @media screen and (max-width: 576px) {
            margin-left: 0px !important;
        }

        .dropdwon-option,
        .add-new-client-btn {
            width: 260px;
        }

        .dropdwon-option {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid var(--primary-yellow);
                background-color: var(--primary-light-yellow);
            }

            .option-client-email {
                color: var(--secondary-email-text);
            }

            .option-client-email,
            .option-client-company-name {
                width: 240px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 100%;
            }
        }
    }

    .dropdown-menu-icon {
        line-height: 1 !important;
        margin-top: 2px !important;
    }

    .dropdown-menu-icon::before {
        line-height: 1 !important;
    }

    .delete-icon::before {
        margin-top: 7px;
    }

    .flatpicker-expires-field {
        width: 150px !important;
        padding: 0.47rem 0.75rem !important;

        @media screen and (max-width: 576px) {
            width: 100px;
            font-size: 12px;
        }
    }

    .invoice-name-description {
        width: 90px;

        .invoice-data-container {
            width: 330px !important;
            max-width: 330px !important;

            @media screen and (max-width: 1290px) {
                width: 300px !important;
            }

            @media screen and (max-width: 1055px) {
                width: 200px !important;
            }

            @media screen and (max-width: 768px) {
                width: 200px !important;
            }

            @media screen and (max-width: 576px) {
                width: 150px !important;
                max-width: 200px !important;
            }

            .invoice-info {
                width: 350px;
                max-width: 350px !important;
                white-space: break-spaces;

                @media screen and (max-width: 1290px) {
                    width: 270px !important;
                }

                @media screen and (max-width: 1055px) {
                    width: 200px !important;
                }

                @media screen and (max-width: 768px) {
                    width: 200px !important;
                }

                @media screen and (max-width: 576px) {
                    width: 150px !important;
                    max-width: 200px;
                }
            }
        }
    }
}