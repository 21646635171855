.get-paid-container {
    max-width: 900px;
    margin: auto;

    .paymentInfo {
        color: var(--secondary-email-text);
    }

    .card-icon {
        top: 2px;
        margin-left: 2px;
        position: relative;
    }

    .alert-message {
        background-color: #fdf1d1 !important;
        border-radius: 10px !important;
    }

    .stripe-icon {
        font-size: 50px;
    }

    .font-size-30{
        font-size: 30px !important;
    }

    .link-account-btn {
        background-color: #ededed;
        width: -webkit-fill-available;

        &:first-child:active,
        &:hover {
            background-color: #ededed;
        }
    }

    .payment-card {
        border: 1px solid var(--secondary-green);
    }
    
    .dropdown-container{
        height: 25px;
    }
    
    .bordered-payment-card {
        border: 1px dashed var(--secondary-email-text);
    }

    .checkbox {
        display: flex;
        justify-content: space-between;

        .control {
            font-family: arial;
            display: block;
            position: relative;
            padding-left: 50px;
            margin-bottom: 1px;
            padding-top: 6px;
            cursor: pointer;
            font-size: 16px;
        }

        .control input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .control_indicator {
            position: absolute;
            top: 0px;
            left: 0;
            height: 25px;
            width: 25px;
            background: #00c285;
            border: 0px solid #00c285;
            border-radius: 3px;
        }
       
        .control input:disabled~.control_indicator {
            background: #00c285;
            opacity: 35;
            pointer-events: none;
        }

        .control_indicator:after {
            box-sizing: unset;
            content: '';
            position: absolute;
            display: none;
        }

        .control input:checked~.control_indicator:after {
            display: block;
        }

        .control-checkbox .control_indicator:after {
            left: 10px;
            top: 5px;
            width: 3px;
            height: 11px;
            border: solid #ffffff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        .control-checkbox input:disabled~.control_indicator:after {
            border-color: #ffff;
        }
    }

    .payment-card{

        @media screen and (min-width:768px) {
            width: 308px !important;
        }
    }
}

.alert-message{
    background-color: #fdf1d1 !important;
    border-color:#fdf1d1 !important ;
}

.payment-completed{
    background-color: #70ddff2e !important;
    border: #70ddff2e !important;
}