.people-list-page {
    .search-info-icon {
        right: 6px;
        top: 13px;
    }

    .search-tooltip {
        display: none;
    }

    .search-info-icon:hover {
        .search-tooltip {
            display: block;
            color: var(--primary-white);
            background-color: var(--primary-black);
            border-radius: 5px;
            // font-size: $font-size-13;
            z-index: 10;
            max-width: 217px;
            min-width: 217px;
            white-space: normal;
            overflow-wrap: break-word !important;
            position: absolute;
            font-family: var(--bs-body-font-family);
            font-style: normal;
            left: -112px;
            top: 60px;
            margin-top: -34px;
            padding: 5px 10px;
            line-height: 1.5;

            @media (max-width: 765px) {
                left: -188px;
            }
        }
    }

    .col-profile {
        width: 5%;
    }

    .col-type {
        width: 30%;
    }

    .col-Action {
        min-width: 5%;
        text-align: end;
    }

    td:first-child {
        padding: 3px 0 !important;
    }

    .avtar-x {
        height: 2.5rem;
        width: 2.5rem !important;
        padding: 0 !important;
    }

    .bg-primary-subtle {
        background-color: var(--secondary-light-green) !important;
        color: var(--secondary-green) !important;
    }

    .col-client_company_name,
    .col-client_email {
        width: 30%;
    }

    .col-client_email_{
        width: 20%;
    }

    .company-name-email {
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
    }

    .gray-email {
        color: var(--secondary-email-text);
        font-size: 12px;
    }

    .dot-icon {
        margin-right: 12px;
    }

    // for close icon input type search
    input[type="search"]::-webkit-search-decoration:hover,
    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button {
        padding-bottom: 1px;
        position: absolute;
        right: 20px;
    }

    // no data found
    .nouser_avaibale_container {
        height: 49vh;
        justify-content: center;
        display: flex;
        align-items: center;
        color: var(--primary-gray) !important;
    }

    .select-section {

        @media (max-width: 1022px) {
            font-size: 11px;

            .form-label {
                font-size: 11px !important;
                font-weight: 600;
            }
        }

        .css-b62m3t-container {
            width: 100%;
            z-index: 4;
        }
    }

    .select2-selection {
        .css-13cymwt-control {
            border: 2px solid var(--secondary-white) !important;
            box-shadow: none;
        }

        .css-1fdsijx-ValueContainer {
            padding: 0px 12px;
        }
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .company-name-email {
        // width: 200px;
        width: 285px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
    }

    /* css for displaying ellipsis when name is long */
    .customer_ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .customer_ellipsis:hover::after {
        content: attr(data-title);
        position: absolute;
        // left: 0px;
        // top: 80px;
        left: 12px;
        top: 89px;
        margin-top: -34px;
        padding: 5px 10px;
        border-radius: 5px;
        color: var(--primary-white);
        border-radius: 5px;
        font-size: 13px;
        z-index: 10;
        max-width: 175px;
        min-width: auto;
        white-space: normal;
        overflow-wrap: break-word !important;
    }

    @media (max-width: 600px) {
        .customer_ellipsis:hover::after {
            font-size: 10px !important;
        }
    }

    .people-list-type:focus {
        padding: 10px !important;
        /* Add your desired padding */
        border: none !important;
    }

    .people-list-type {
        width: 111px;
        border: none !important;
        font-size: 12px;

        .type-option:hover {
            background-color: var(--bs-gray-100);
        }

        .css-13cymwt-control {
            border: none !important;
            padding-left: 8px;
        }

        .css-t3ipsp-control {
            border: none !important;
            padding-left: 8px;
        }

        .css-13cymwt-control:hover {
            cursor: pointer !important;
            background: var(--bs-gray-100) !important;
            border-color: transparent !important;
            border: none !important;
            padding-left: 8px;
        }

        .css-1u9des2-indicatorSeparator {
            width: 0 !important;
        }

        .css-10wo9uf-option,
        .css-d7l1ni-option {
            margin: 3px !important;
            width: 60% !important;
            border-radius: 5px !important;
        }

        // just check
        .select-dropdown:after {
            content: '\2713';
            /* Unicode checkmark character */
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            pointer-events: none;
        }

        /* Style for the selected option */
        .select-dropdown option:checked {
            background-color: #f0f0f0;
        }
    }

    @media (max-height: 601px) {
        .table-responsive {
            height: 60vh !important;
        }
    }

    @media (min-height: 602px) and (max-height: 739px) {
        .table-responsive {
            height: 63vh !important;
        }
    }

    @media (min-height: 740px) and (max-height: 1022px) {
        .table-responsive {
            height: 64vh !important;
        }
    }

    @media (min-height: 1023px) {
        .table-responsive {
            height: 80vh !important;
        }
    }

    @media (max-width: 765px) and (max-height: 741px) {
        .table-responsive {
            height: 58vh !important;
        }
    }

    @media (max-width: 765px) and (min-height: 742px) and (max-height: 843px) {
        .table-responsive {
            height: 54vh !important;
        }
    }

    @media (max-width: 765px) and (min-height: 843px) {
        .table-responsive {
            height: 65vh !important;
        }
    }

    @media (max-width: 1024px) {

        .company-name-email {
            width: 140px !important;
        }

        .avtar-x {
            height: 1.5rem !important;
            width: 1.5rem !important;
            padding: 0 !important;
            // font-size: $font-size-9;
        }

        .table-body-font,
        .list-search,
        .list-search::placeholder,
        .add-customer {
            font-size: 11px !important;
            border-radius: 4px !important;
        }
    }
}