.profile-dropdown {
    .avtar-x {
        height: 2rem;
        width: 2rem !important;
    }

    .username-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 312px;
    }

    .bg-primary-subtle {
        background-color: var(--secondary-light-green) !important;
        color: var(--secondary-green) !important;
    }

    .profile-menu-item-text{
        position: relative;
        left: 3px;
    }
}