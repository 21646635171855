@import "../../../../../src/assets/scss/theme.scss";
@import "../../../../../src/assets/scss/custom/components/helperFontsize";

.tax-deduction-list {
    table {
        width: 100% !important;
    }

    .table-responsive {
        height: 72vh !important;

        @media (max-height: 668px) {
            height: 65vh !important;
        }
    }

    td:first-child {
        padding: 3px 0 !important;
    }

    .dropdown-menu-text {
        top: 1px;
        position: relative;
        left: 2px;
    }

    // info icon
    .info-icon {
        font-size: $font-size-14;
        top: 2px;
        left: 2px;
        cursor: pointer;
    }

    // info tooltip
    .info_tooltip {
        top: 20px !important;
        font-size: $font-size-12;
        position: absolute;
        background: var(--primary-black) !important;
        box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
        padding: 6px !important;
        border-radius: 4px;
        white-space: nowrap;
        pointer-events: none;
        transition: 0s;
        display: none;
        word-wrap: break-word;
        white-space: normal;
        font-family: var(--bs-body-font-family);
    }

    .info-icon:hover .info_tooltip {
        z-index: 9;
        display: block;
        pointer-events: auto;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
        color: var(--primary-white);
        max-width: 384px !important;
        left: 8px;
        min-width: 200px;
    }


    // no data found
    .nouser_avaibale_container {
        height: 50vh;
        justify-content: center;
        display: flex;
        align-items: center;
        color: var(--primary-gray) !important;
    }

    // spinner
    .loader {
        position: absolute;
        top: 50%;
        right: 50%;
        color: var(--primary-black) !important;

        .spinner-border.text-light {
            color: var(--primary-black) !important;
        }
    }

    .list-search {
        padding-right: 23px !important;
    }

    @media (max-width: 600px) {
        .dropdown-menu {
            left: 10px !important;
            right: 10px !important;
        }

        .customer_ellipsis:hover::after {
            font-size: $font-size-10;
        }

    }

    // table cell col width
    .col-transaction_id {
        width: 20%;
    }

    .col-email {
        width: 20%;
        position: relative;
    }

    .col-paid_on {
        width: 10%;

        @media screen and (max-width: 576px) {
            padding-right: 20px !important;
        }
    }

    .customer-list-tooltip {
        top: 45px;
        z-index: 9;
        display: none;
        color: var(--primary-white);
        max-width: 384px !important;
        min-width: 200px;
        font-size: $font-size-12;
        position: absolute;
        background: var(--primary-black) !important;
        box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
        padding: 6px !important;
        border-radius: 4px;
    }

    .col-name:hover .customer-list-tooltip,
    .col-email:hover .customer-list-tooltip,
    .customer-shop-name-text:hover .customer-list-tooltip,
    .col-Action:hover .customer-list-tooltip {
        display: block;
    }

    .shop-contact-no-text:hover .customer-list-tooltip {
        display: block;
        top: 55px !important;
    }

    /* css for displaying ellipsis when name is long */
    .customer_ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .customer_ellipsis:hover::after {
        content: attr(data-title);
        position: absolute;
        left: 0px;
        top: 80px;
        margin-top: -34px;
        padding: 5px 10px;
        border-radius: 5px;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: $font-size-13;
        z-index: 10;
        max-width: 280px;
        min-width: auto;
        white-space: normal;
        overflow-wrap: break-word !important;
    }

    .shop-email-tooltip:hover::after {
        top: 89px !important;
    }

    .shop-name-tooltip:hover::after {
        top: 85px !important;
    }

    /*customer name  */
    .customer {
        width: 158px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
        display: block;
    }

    .email-text{
        color: var(--secondary-email-text);
        font-size: 12px;
    }

    .paid-on-text{
        max-width: 100px;
        width: 100px;

        @media screen and (max-width: 576px) {
            width: 85px;
        }
    }

    // customer email & shop email

    .customer-email-text,
    .shop-contact-no-text {
        width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
    }

    // shop name
    .customer-shop-name-text {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
    }

    .select2-selection .css-13cymwt-control {
        border: 2px solid var(--secondary-white) !important;
        box-shadow: none !important;
    }

    @media (max-width: 1024px) {

        .customer-email-text,
        .shop-contact-no-text,
        .customer {
            width: 100px !important;
        }

        .customer-shop-name-text {
            max-width: 100px !important;
        }

        .img-thumbnail,
        .avtar-x {
            height: 1.5rem !important;
            width: 1.5rem !important;
            padding: 0 !important;
            font-size: $font-size-9;
        }

        .table-body-font,
        .list-search,
        .list-search::placeholder,
        .add-customer {
            font-size: $font-size-11;
            border-radius: 4px !important;
        }

        .list-search {
            width: 100% !important;
        }

        .search-btn {
            background-color: var(--primary-green) !important;
        }

        .customer-list td:first-child {
            padding: 0 !important;
        }
    }

    // for close icon input type search
    input[type="search"]::-webkit-search-decoration:hover,
    input[type="search"]::-webkit-search-cancel-button:hover {
        cursor: pointer;
    }

    input[type="search"]::-webkit-search-cancel-button {
        padding-bottom: 1px;
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .bg-primary-subtle {
        background-color: var(--secondary-light-green) !important;
        color: var(--secondary-green) !important;
    }

    .dropdown-menu-icon {
        line-height: 1 !important;
        margin-top: 2px !important;
    }

    .dropdown-menu-icon::before {
        line-height: 1 !important;
    }

    .search-info-icon {
        right: 6px;
        top: 13px;
    }

    .search-tooltip {
        display: none;
    }

    .search-info-icon:hover {
        .search-tooltip {
            display: block;
            color: var(--primary-white);
            background-color: var(--primary-black);
            border-radius: 5px;
            font-size: $font-size-13;
            z-index: 10;
            width: max-content;
            white-space: normal;
            overflow-wrap: break-word !important;
            position: absolute;
            font-family: var(--bs-body-font-family);
            font-style: normal;
            left: -112px;
            top: 60px;
            margin-top: -34px;
            padding: 5px 10px;
            line-height: 1.5;

            @media (max-width: 765px) {
                left: -188px;
            }
        }
    }
}

.flatpickr-monthSelect-month.today:hover{
    background-color: var(--secondary-green) !important;
    border-color: var(--secondary-green) !important;
}

.flatpickr-monthSelect-month.today,
.flatpickr-monthSelect-month.selected{
    border-color: var(--secondary-green) !important;
}