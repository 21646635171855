@import '../../../../assets/scss/custom/components/helperFontsize';

.linear-quote-calculator {
    position: absolute;
    left: 70px;
}

.remove-scroll {
    height: 100vh;
    overflow: hidden;
}

.min-width-325 {
    min-width: 325px !important;
}

.min-width-350 {
    min-width: 350px !important;
}

.step-right-0 {
    right: 0px !important;
    left: auto;
}

.step-left-0 {
    left: 0 !important;
}

@media screen and (max-width: 576px) {
    .step-righ-left {
        left: auto !important;
        right: 0px !important;
    }
}

.info-icon-margin {
    margin-top: 0px !important;
    top: -4px;
}

/* Custom class for Intro.js tooltips */
.setup-table-into {
   min-width: 400px !important;
   overflow-y: auto;
   top: auto !important;
   overflow-x: hidden;
   height: 400px;

   @media screen and (max-width: 576px) {
        min-width: 250px !important;
        left: 0px !important;
   }
}

.text-left {
    text-align: left !important;
}

.custom-editor{
    max-height: 100px !important;
    font-family: "Poppins', sans-serif";

    @media screen and (max-width: 820px) {
        max-height: 60px !important;
    }

    @media screen and (device-height: 600px) {
        max-height: 30px !important;
    }

    @media screen and (device-height: 800px) {
        max-height: 80px !important;
    }
}

.job_profit_margin_container {
    margin-right: 20px;
    margin-left: 0px;

    .info-icon-spacing {
        top: -1px;
    }
}

.custom-quote-cal-tab {
    max-width: 471px;
    width: -webkit-fill-available;
    border: 2px solid var(--secondary-green) !important;
    border-radius: 7px;
    
    .nav-item {
        position: relative;
        color: var(--primary-black);
        &:nth-child(1) {
            .nav-link {

                border-top-left-radius: 4px;   
                border-bottom-left-radius: 4px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    
        &:nth-child(2) {
            .nav-link {

                border-top-left-radius: 0px;   
                border-bottom-left-radius: 0px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        
        .nav-link {
            position: relative;
            border: none;
            padding: 0.3rem !important;

            span {
                font-size: 15px;
            }

            

            &::after {
                content: "";
                background: var(--secondary-green); 
                position: absolute;
                width: 100%;
                transition: all 250ms ease; 
                transform: scale(0);
                transform-origin: left; 
            }

            &:hover {
                color: var(--primary-green) !important;
            }

            &.active-tab {
                color: var(--primary-white) !important;
                background-color: var(--secondary-green) !important;

    
                &::after {
                    transform: scale(1);
                }
            }
        }
    }
}


.quote-calculator {
    padding: calc(70px + 0px) calc(24px * 0.75) 30px calc(24px * 0.75) !important;

    @media screen and (min-width:765.99px) and (max-width:991.99px) {
        left: 0px;
        padding: calc(48px + 0px) calc(24px * 0.75) 30px calc(24px * 0.75) !important;
    }

    .reset-button {
        top: 2px;
    }

    .statistics-card-screen-printing-row {
        .result-card-col {
            @media screen and (min-width: 1200px) {
                width: 20% !important;
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .custom-charges-label {
        @media screen and (max-width: 576px) {
            min-width: 95px !important;
        }

        .info-icon {
            @media screen and (max-width: 576px) {
                position: relative !important;
            }
        }
    }

    .ink-charges-label {
        @media screen and (max-width: 576px) {
            min-width: 92px !important;
        }

        .info-icon {
            @media screen and (max-width: 576px) {
                position: relative !important;
            }
        }
    }

    .quick-tour-btn {
        background-color: var(--primary-white);
        border-radius: 4px;
        color: var(--primary-yellow);
        border: 1px solid var(--primary-yellow);
        font-size: 12px;
    }

    .quick-tour-btn:hover {
        background: var(--primary-yellow);
        color: var(--primary-white);
    }

    .quick-tour-icon {
        color: var(--primary-white);
        background: var(--primary-yellow);
        border-radius: 50%;
        font-size: 30px;
        left: -18px;
        position: absolute;
    }

    .tab-view-quick-tour {
        @media screen and (max-width: 600px) {
            justify-content: end;            
        }

        .quick-tour-icon {
            @media screen and (max-width:600px) {
                left: auto;
                right: 70px;
            }       
        }
    }


    .result-card-col {
        margin-bottom: 12px;

        .result-card {
            padding-right: 10px;
            padding-left: 10px;
        }

        .card {
            height: 100%;
            margin-bottom: 0 !important;
        }
    }

    .info-icon-spacing {
        transform: translate(3px, -3px);
    }

    .close-sidebar-info-icon {
        transform: translate(3px, 3px);

        @media screen and (max-width: 576px) {
            transform: none !important;
        }
    }

    .header-input-card {
        .card-body {
            padding: 11px !important;
        }
    }

    .card {
        margin-bottom: 12px !important;
    }

    .markup-table-card {
        margin-bottom: 54px !important;
    }

    .card-table {
        padding-right: 8px;
        padding-left: 8px;

        .card-body {
            padding: 2px;

            @media screen and (max-width:765.99px) {
                padding: 6px;
            }
        }
    }

    .form-group-margin-0 {
        margin-bottom: 0 !important;
    }

    .avatar-sm {
        height: 2.5rem;
        width: 2.5rem !important;

        @media (min-width: 1199.98px) and (max-width: 1358.98px) {
            height: 1.2rem;
            width: 1.2rem !important;
        }
    }

    @media (min-width: 1199.98px) and (max-width: 1333.98px) {
        .statistic-label {
            font-size: 12px;
        }
    }

    .add-text-button {
        color: var(--primary-green);
        font-weight: bold;
        border: none !important;

        &:hover {
            color: var(--secondary-green);
        }
    }

    .grid-view {
        @media (min-width: 1022.99px) {
            .statistic-card-row {
                position: sticky;
                top: 69px;
                background-color: #f8f8fb;
                z-index: 1;
                padding-top: 12px;
                margin-top: -12px;
            }

            .statistic-card-row.introjs-fixParent {
                z-index: 1 !important;
            }

            .statistic-card-row-scroll {
                padding-top: 20px;
            }
        }
        .ink-count-head {
            width: 130px;

            @media screen and (max-width:600px) {
                width: 60px;
            }
        }

        .art-count-head {
            width: 100px !important;
        }

        .setup-table-head {
            width: 130px;

            @media screen and (max-width:600px) {
                width: 60px;
            }
        }
    }


    .avatar-title {
        background-color: var(--primary-light-yellow) !important;
        color: var(--primary-yellow) !important;
    }

    .btn-secondary-add {
        background-color: var(--secondary-green);
        border-radius: 4px;
        padding: 8px 15px;
        color: var(--primary-white);
        border: 1px solid var(--secondary-green);
    }

    @media (max-width: 392px) {

        .btn-secondary-add,
        .btn-fill {
            padding: 8px 8px;
        }
    }

    .btn-secondary-add:hover {
        background-color: var(--secondary-dark-green);
        border-color: var(--secondary-dark-green);
    }

    .btn-secondary-add:disabled {
        cursor: no-drop;
        opacity: .65;
    }

    .btn-secondary-add:disabled:hover {
        background-color: var(--secondary-green);
    }
    

    .calculator-td {
        border: none !important;
        text-align: center;
        padding: 7px !important;
        min-width: 74px;

        input {
            width: 75%;
            padding: 5px 10px;
            margin: 0 auto;
            text-align: center;

            @media (max-width: 765px) {
                width: 100% !important;
            }
        }
    }

    .setup-td {
        @media screen and (max-width: 600px) {
            min-width: 58px !important;
        }
    }

    .calculator-th {
        text-align: center;
        background-color: var(--bs-card-bg) !important;
    }

    .quote-salesPrice-title {
        height: 50px !important;
        color: black;
        font-weight: 600;
        border-bottom-width: 1px !important;
        vertical-align: bottom;
        text-align: left !important;
        padding-left: 24px;
    }

    .auto-populate {
        color: var(--primary-gray);
        background-color: var(--primary-dark-white) !important;
    }

    .fill-in {
        color: var(--primary-green);
    }

    .fill-in-input {
        border: 2px solid var(--secondary-white) !important;
    }

    .auto-populate-input {
        background-color: transparent !important;
        border: none;
    }

    .editable-input:focus,
    .editable-input {
        background-color: var(--primary-light-yellow) !important;
        border: 1px solid var(--primary-yellow) !important;
    }

    .auto-populated-input-text {
        color: var(--primary-gray) !important;
        font-weight: 800;
    }

    .auto-populated-input {
        border: 2px solid var(--secondary-white) !important;
    }

    .total-value {
        color: var(--secondary-green);
        font-weight: 500;
    }

    .total-input:focus,
    .total-input {
        color: var(--secondary-green);
        font-weight: 600;
        font-size: $font-size-15;
    }

    .statistic-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
        width: 88px;

        @media (min-width: 1600px) {
            width: 152px;
        }
    }

    /* css for displaying ellipsis when name is long */
    .quote_ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .quote_ellipsis:hover::after {
        content: attr(data-title);
        position: absolute;
        left: 0px;
        bottom: -27px;
        margin-top: -34px;
        padding: 5px 10px;
        border-radius: 5px;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: $font-size-13;
        z-index: 10;
        max-width: 175px;
        min-width: auto;
        white-space: normal;
        overflow-wrap: break-word !important;
    }

    // for info tooltip
    .info-tooltip:hover {
        overflow: visible !important;
    }

    .info-tooltip:hover::after {
        font-family: var(--bs-body-font-family);
        font-style: normal;
        content: attr(data-title);
        position: absolute;
        left: -112px;
        top: 60px;
        margin-top: -34px;
        padding: 5px 10px;
        border-radius: 5px;
        color: var(--primary-white);
        background-color: var(--primary-black);
        border-radius: 5px;
        font-size: $font-size-13;
        z-index: 10;
        max-width: 236px;
        min-width: 236px;
        white-space: normal;
        overflow-wrap: break-word !important;
    }

    .badge-span {
        width: 48px;
        height: 17px;
        display: inline-block;
    }

    .badge-indicator {
        font-size: $font-size-12;
        background-color: transparent !important;
    }

    .editable {
        color: var(--primary-yellow);
    }

    .calculator-footer {
        position: fixed;
        right: 0;
        bottom: 0;
        background-color: var(--primary-white);
        z-index: 100;

        .quote-item-tooltip {
            display: none;
        }

        .quote-id-info:hover .quote-item-tooltip {
            position: absolute;
            background: var(--primary-black) !important;
            transition: 0s;
            word-wrap: break-word;
            white-space: normal;
            font-family: var(--bs-body-font-family);
            top: -30px !important;
            display: block;
            pointer-events: auto;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px 1px;
            right: -61px !important;
            color: var(--primary-white);
            width: 135px !important;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: $font-size-13;
            z-index: 10;
            font-family: var(--bs-body-font-family);
            line-height: 1.5;
            font-style: normal !important;

            @media screen and (max-width:576px) {
                right: 0px !important;
            }
        }
    }

    .calculator-footer .text-yellow {
        color: var(--primary-yellow);
    }

    .table-nowrap .calculator-th,
    .table-nowrap .calculator-td {
        white-space: break-spaces;
    }

    @media (min-width: 1199.98px) {
        .stitch-count-head {
            white-space: nowrap !important;
        }
    }

    @media (max-width: 765px) {
        .table-nowrap .th-width {
            width: 370px !important;
        }

        .table-nowrap .calculator-td {
            padding: 5px 0px !important;
        }
    }

    .form-control-header {
        border: 2px solid var(--secondary-white) !important;
    }

    .item-name-error-message {
        color: var(--bs-form-invalid-color);
        font-size: 80%;
    }

    // for switch
    .react-switch-bg {
        height: 25px !important;
        width: 55px !important;
        background: var(--secondary-green) !important;
    }

    .switch-icon {
        font-size: 24px;
        position: absolute;
        top: 1px;
        color: var(--secondary-light-green);
    }

    .react-switch-handle {
        height: 21px !important;
        width: 21px !important;
        top: 2px !important;
        left: -4px;
    }

    @media (min-width: 1199.98px) and (max-width: 1399.98px) {
        .react-switch-handle {
            top: 2px !important;
        }

        .switch-icon {
            top: 0px;
        }
    }

    .bx-grid-horizontal {
        left: 8px;
    }

    .bx-grid-vertical {
        right: 7px;
    }

    // for linear view
    .linear-view {
        .setup-table-card {
            margin-bottom: 8px !important;
        }

        .item-name-quantity-body-card {
            padding: 10px 20px;
        }

        .card-table {
            padding-right: 4px;
            padding-left: 4px;
        }

        .calculator-th {
            text-align: center;
            background-color: var(--bs-card-bg) !important;
            padding: 0.65rem 0rem !important;
            font-size: 12px;
        }

        .stitch-count-calculator-th,
        .setup-calculator-th {
            min-width: 94px !important;

            @media screen and (min-width: 1200px) and (max-width: 1360.99px) {
                min-width: 70px !important;
            }
        }

        .ink-count-calculator-th {
            min-width: 101px !important;
        }

        .ink-count-head {
            width: 140px !important;

            @media screen and (max-width:600px) {
                width: 60px !important;
            }
        }

        .art-count-head {
            width: 100px !important;
        }

        .setup-table-head {
            width: 130px;

            @media screen and (max-width:600px) {
                width: 60px !important;
            }
        }

        .job-criteria-table {
            width: 100px !important;
        }

        .quote-salesPrice-title {
            padding-left: 24px !important;
        }

        .calculator-td {
            border: none !important;
            text-align: center !important;
            padding: 4px 2px !important;
            min-width: 70px;
            font-size: 12px !important;

            input {
                width: 75%;
                padding: 5px 5px;
                margin: 0 auto;
                text-align: center;
                height: 25px !important;
                font-size: 12px;

                @media (max-width: 765px) {
                    width: 100% !important;
                }
            }
        }

        .calculator-tech-skeleton {
            width: 150px;
        }

        .total-input {
            font-size: $font-size-15;
            padding-left: 0px !important;
        }

        .total-value {
            padding-right: 26px !important;
            text-align: end !important;
        }

        .result-card-col,
        .card {
            margin-bottom: 6px !important;
        }

        .screen-printing-table {
            .linear-table-card-body {
                @media screen and (min-width: 1200px) and (max-width: 1600px) and (max-height: 800px) {
                    .calculator-th {
                        padding: 2px 0px !important;
                    }

                    .calculator-td {
                        padding: 2px 2px !important;
                    }

                    .result-card-col,
                    .card {
                        margin-bottom: 3px !important;
                    }
                }
            }
        }        


        .linear-table-card-body {
            padding: 2px;
        }

        .markup-table-card {
            margin-bottom: 0px;

            @media screen and (min-width: 767.99px) and (max-width: 1200.99px) {
                margin-bottom: 24px !important;
            }
        }
    }
}

.preview-quote-table-responsive {
    max-height: 700px;

    @media screen and (max-height: 600px) {
        max-height: 400px;
    }

    @media screen and (min-height: 601px) and (max-height: 800px) {
        max-height: 480px;
    }

    .preview-quote-table {
        .preview-quote-table-head {
            position: sticky;
            top: 0;
            z-index: 100;

            .table-th {
                background-color: var(--secondary-light-green);
            }

            .table-rate-th {
                width: 100px;
            }

            .table-quantity-th {
                width: 70px;
            }

            .table-name-th {
                width: 210px !important;
            }

            .table-action-th {
                width: 120px !important;
            }
        }

        .table-data {
            vertical-align: middle;

            .tax-display-quote-items-popup {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 60px;
                max-width: 60px !important;
                text-align: start;
                color: var(--primary-gray);
            }

            .item_name {
                width: 170px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .item_description {
                width: 170px;
                color: var(--secondary-email-text) !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }            
        }

     

        .wrapper {
            display: inline-block;
        }

        .btnimg {
            color: var(--bs-gray);
            background: var(--bs-gray-100);
            pointer-events: none;
            width: 28px;
            height: 28px;
            opacity: 1;
            text-align: center;
            border-radius: 4px;
            padding-top: 1px;
        }

        .quote-file {
            opacity: 0;
            position: absolute;
            top: 22px;
            left: 43px;
            width: 28px;
            height: 28px;
            cursor: pointer !important;
        }

        input[type=file]::-webkit-file-upload-button {
            /* chromes and blink button */
            cursor: pointer;
        }

        .image-preview {
            height: 28px;
            width: 28px;
        }

        .item-img-delete {
            left: 65px;
            border-radius: 50%;
            background: var(--primary-light-red) !important;
            top: 31px;
            font-size: 11px !important;
            line-height: 1;
            border-radius: 50%;
            padding: 4px 3px 2px 3px;
            display: none;
        }

        @media (max-width: 765px) {
            .item-img-delete {
                left: 35px;
            }
        }

        @media (min-width: 765px) and (max-width: 778px) {
            .item-img-delete {
                left: 41px;
            }
        }

        .img-preview-div:hover .item-img-delete {
            display: block;
        }

        .image-error-msg {
            font-size: 80%;
            left: 0;
        }

        .img-td {
            white-space: unset !important;
            width: 15%;
            height: 63px;
        }
    }
}


.preview-img-modal {
    @media (min-width: 1022.99px) {
        max-width: none !important;
    }

    .modal-content {
        height: 62%;

        .modal-header {
            border-bottom: none;
        }

        .modal-body {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin: 0 44px 44px 44px !important;
        }

        .modal-preview-img {
            position: absolute;
            bottom: 44px;

            @media (min-width: 1022.99px) {
                height: calc(100% - 44px);
            }

            // max-width: calc(100% - 88px);
            @media (max-width: 1022.99px) {
                max-width: calc(100% - 88px);
            }
        }
    }
}

.no-item-added-to-quote-info {
    color: var(--primary-gray);
}

// for date-picker
.flatpickr-weekdays,
span.flatpickr-weekday {
    background: var(--secondary-green) !important;
}

// save quote modal
.save-quote-modal {
    max-width: 55% !important;

    @media (max-width: 765px) {
        max-width: 100% !important;
    }

    .form-check-inline {
        margin-right: 2rem;
    }

    // for invalid message
    .invalid-msg {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: var(--bs-form-invalid-color);
    }

    // remove arrow from input type number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }



    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }

    // textarea resize
    .notes-textarea {
        resize: vertical;
        max-height: 100px !important;
    }

    .radio-btn-label:checked {
        background-color: var(--secondary-green);
        border-color: var(--secondary-green);
    }

    .client-radio-label {
        font-weight: 300 !important;
    }

    // for select tag options style
    .css-1nmdiq5-menu {
        background-color: var(--primary-white) !important;
        overflow-y: scroll;
        height: 155px;

        .css-1n6sfyn-MenuList {
            max-height: 155px;
        }

        @media (max-width: 1025.98px) {
            height: 111px;

            .css-1n6sfyn-MenuList {
                max-height: 111px;
            }
        }
    }

    .select2-selection {
        .css-13cymwt-control {
            border: 2px solid var(--secondary-white) !important;
            box-shadow: none;
        }
    }

    // change form-control border
    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .select2-selection {
        padding: 0;

        @media (min-width: 1023.98px) {
            width: 50%;
        }
    }

    // wizard
    .form-wizard-wrapper {
        label {
            font-size: $font-size-14;
            text-align: right;
        }
    }

    .wizard {

        @media screen and (max-width: 576px) {
            overflow-y: scroll;
        }

        // step
        .steps {
            >ul {
                display: flex;
                flex-wrap: wrap;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;
                border-bottom: 2px solid var(--secondary-light-green);

                @media (max-width: 1199.98px) {
                    // flex-direction: column;
                }

                >a,
                >li {
                    .quote-calculator {

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        .result-card-col {
                            // padding-left: 12px !important;
                            // padding-right: 12px !important;
                            margin-bottom: 12px;

                            .result-card {
                                padding-right: 10px;
                                padding-left: 10px;
                            }

                            .card {
                                height: 100%;
                                margin-bottom: 0 !important;
                            }
                        }

                        .header-input-card {
                            .card-body {
                                padding: 11px;
                            }
                        }

                        .card {
                            margin-bottom: 12px !important;
                        }

                        .card-table {
                            padding-right: 8px;
                            padding-left: 8px;

                            .card-body {
                                padding: 2px;
                            }
                        }

                        .form-group-margin-0 {
                            margin-bottom: 0 !important;
                        }

                        .avatar-sm {
                            height: 2.5rem;
                            width: 2.5rem !important;
                        }

                        .grid-view {
                            @media (min-width: 1022.99px) {
                                .statistic-card-row {
                                    position: sticky;
                                    top: 74px;
                                    background-color: #f8f8fb;
                                    z-index: 1000;
                                }
                            }

                            .ink-count-head {
                                width: 130px;

                                @media screen and (max-width:600px) {
                                    width: 60px !important;
                                }
                            }

                            .art-count-head {
                                width: 100px !important;
                            }

                            .setup-table-head {
                                width: 130px;

                                @media screen and (max-width:600px) {
                                    width: 60px !important;
                                }
                            }
                        }

                        .avatar-title {
                            background-color: var(--primary-light-yellow) !important;
                            color: var(--primary-yellow) !important;
                        }

                        .btn-secondary-add {
                            background-color: var(--secondary-green);
                            border-radius: 4px;
                            padding: 8px 15px;
                            color: var(--primary-white);
                            border: 1px solid var(--secondary-green);
                        }

                        @media (max-width: 392px) {

                            .btn-secondary-add,
                            .btn-fill {
                                padding: 8px 8px;
                            }
                        }

                        .btn-secondary-add:hover {
                            background-color: var(--secondary-dark-green);
                            border-color: var(--secondary-dark-green);
                        }

                        .btn-secondary-add:disabled {
                            cursor: no-drop;
                            opacity: .65;
                        }

                        .btn-secondary-add:disabled:hover {
                            background-color: var(--secondary-green);
                        }

                        .calculator-td {
                            border: none !important;
                            text-align: center;
                            padding: 7px !important;
                            min-width: 74px;

                            input {
                                width: 75%;
                                padding: 5px 10px !important;
                                margin: 0 auto;
                                text-align: center;

                                @media (max-width: 765px) {
                                    width: 100% !important;
                                }
                            }
                        }

                        .calculator-th {
                            text-align: center;
                            background-color: var(--bs-card-bg) !important;
                        }

                        .quote-salesPrice-title {
                            height: 50px !important;
                            color: black;
                            font-weight: 600;
                            border-bottom-width: 1px !important;
                            vertical-align: bottom;
                            text-align: left !important;
                            padding-left: 24px;
                        }

                        .auto-populate {
                            color: var(--primary-gray);
                            background-color: var(--primary-dark-white) !important;
                        }

                        .fill-in {
                            color: var(--primary-green);
                        }

                        .fill-in-input {
                            border: 2px solid var(--secondary-white) !important;
                        }

                        .auto-populate-input {
                            background-color: transparent !important;
                            border: none;
                        }

                        .editable-input:focus,
                        .editable-input {
                            background-color: var(--primary-light-yellow) !important;
                            border: 1px solid var(--primary-yellow) !important;
                        }

                        .total-value {
                            color: var(--secondary-green);
                            font-weight: 500;
                        }

                        .total-input:focus,
                        .total-input {
                            color: var(--secondary-green) !important;
                            font-weight: 600 !important;
                            font-size: $font-size-15;
                        }

                        .statistic-value {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            height: 100%;
                            width: 88px;

                            @media (min-width: 1600px) {
                                width: 152px;
                            }
                        }

                        /* css for displaying ellipsis when name is long */
                        .quote_ellipsis {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                        }

                        .quote_ellipsis:hover::after {
                            content: attr(data-title);
                            position: absolute;
                            left: 0px;
                            bottom: -27px;
                            margin-top: -34px;
                            padding: 5px 10px;
                            border-radius: 5px;
                            color: var(--primary-white);
                            background-color: var(--primary-black);
                            border-radius: 5px;
                            font-size: $font-size-13;
                            z-index: 10;
                            max-width: 175px;
                            min-width: auto;
                            white-space: normal;
                            overflow-wrap: break-word !important;
                        }

                        // for info tooltip
                        .info-tooltip:hover {
                            overflow: visible !important;
                        }

                        .info-tooltip:hover::after {
                            font-family: var(--bs-body-font-family);
                            font-style: normal;
                            content: attr(data-title);
                            position: absolute;
                            left: -112px;
                            top: 60px;
                            margin-top: -34px;
                            padding: 5px 10px;
                            border-radius: 5px;
                            color: var(--primary-white);
                            background-color: var(--primary-black);
                            border-radius: 5px;
                            font-size: $font-size-13;
                            z-index: 10;
                            max-width: 236px;
                            min-width: 236px;
                            white-space: normal;
                            overflow-wrap: break-word !important;
                        }

                    }

                    .preview-quote-table {
                        .table-th {
                            background-color: var(--secondary-light-green);
                        }
                    }

                    .no-item-added-to-quote-info {
                        color: var(--primary-gray);
                    }

                    // for date-picker
                    .flatpickr-weekdays,
                    span.flatpickr-weekday {
                        background: var(--secondary-green) !important;
                    }

                    .save-quote-modal {
                        max-width: 55% !important;

                        @media (max-width: 765px) {
                            max-width: 100% !important;
                        }

                        .form-check-inline {
                            margin-right: 2rem;
                        }

                        // for invalid message
                        .invalid-msg {
                            width: 100%;
                            margin-top: 0.25rem;
                            font-size: 80%;
                            color: var(--bs-form-invalid-color);
                        }

                        // remove arrow from input type number
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        input[type=number] {
                            appearance: textfield;
                            -moz-appearance: textfield;
                        }

                        // textarea resize
                        .notes-textarea {
                            resize: vertical;
                        }

                        .radio-btn-label:checked {
                            background-color: var(--secondary-green);
                            border-color: var(--secondary-green);
                        }

                        .client-radio-label {
                            font-weight: 300 !important;
                        }

                        .select2-selection {
                            .css-13cymwt-control {
                                border: 2px solid var(--secondary-white) !important;
                                box-shadow: none;
                            }
                        }

                        // change form-control border
                        .form-control {
                            border: 2px solid var(--secondary-white) !important;
                        }

                        .select2-selection {
                            padding: 0;
                        }

                        // wizard
                        .form-wizard-wrapper {
                            label {
                                font-size: $font-size-14;
                                text-align: right;
                            }
                        }

                        .wizard {

                            // step
                            .steps {
                                >ul {
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding-left: 0;
                                    margin-bottom: 0;
                                    list-style: none;
                                    border-bottom: 2px solid var(--secondary-light-green);

                                    @media (max-width: 1199.98px) {
                                        // flex-direction: column;
                                    }

                                    >a,
                                    >li {
                                        // flex-basis: 0;
                                        // flex-grow: 1;
                                    }

                                    >li {
                                        @media (max-width: 765px) {
                                            width: 25%;
                                        }

                                        width: 15%;

                                        a {
                                            display: block;
                                            padding: .5rem 1rem;
                                            font-weight: 600;
                                            border-bottom: 4px solid transparent;
                                            width: 100%;
                                            text-align: center;
                                            font-size: $font-size-15;
                                        }
                                    }

                                    .current-info {
                                        position: absolute;
                                        left: -999em;
                                    }
                                }

                                .number {
                                    display: inline-block;
                                    width: 38px;
                                    height: 38px;
                                    line-height: 34px;
                                    border: 2px solid var(--primary-green);
                                    color: var(--secondary-green);
                                    text-align: center;
                                    border-radius: 50%;
                                    margin-right: .5rem;
                                }

                                .current {

                                    a,
                                    a:active,
                                    a:hover {
                                        color: var(--secondary-green);
                                        border-bottom: 4px solid var(--secondary-green);
                                        width: 100%;

                                        .number {
                                            background-color: var(--secondary-green);
                                            color: var(--secondary-green);
                                        }
                                    }
                                }
                            }

                            // content
                            >.content {
                                background-color: transparent;
                                padding: 14px;
                                margin-top: 0;
                                border-radius: 0;
                                // min-height: 150px;
                                height: 70vh;

                                @media (max-height: 648px) {
                                    // height: 65vh !important;
                                    height: 62vh !important;
                                }

                                @media (min-height: 649px) and (max-height: 699px) {
                                    height: 59vh !important;
                                }

                                @media (min-height: 699px) and (max-height: 701px) {
                                    height: 50vh !important;
                                }

                                @media (min-height: 701px) and (max-height: 750px) {
                                    // height: 54vh !important;
                                    height: 52vh !important;
                                }

                                @media (min-height: 750px) and (max-height: 850px) {
                                    // height: 50vh !important;
                                    height: 47vh !important;
                                }

                                @media (min-height: 850px) and (max-height: 1022.99px) {
                                    // height: 44vh !important;
                                    height: 30vh !important;
                                }


                                @media (min-height: 1023px) and (max-height: 1100px) {
                                    // height: 44vh !important;
                                    height: 39vh !important;
                                }

                                @media (min-height: 1100px) {
                                    // height: 35vh !important;
                                    height: 29vh !important;
                                }

                                >.title {
                                    position: absolute;
                                    left: -999em;
                                }

                                >.body {
                                    width: 100%;
                                    height: 100%;
                                    padding: 14px 0 0;
                                    position: static
                                }
                            }

                            // actions

                            >.actions {
                                position: relative;
                                display: block;
                                text-align: right;
                                width: 100%;

                                >ul {
                                    display: block;
                                    text-align: right;
                                    padding-left: 0;

                                    >li {
                                        display: inline-block;
                                        margin: 0 0.5em;
                                        width: 15%;
                                    }
                                }

                                a,
                                a:active,
                                a:hover {
                                    background-color: var(--secondary-green);
                                    border-radius: 4px;
                                    padding: 8px 15px;
                                    color: var(--secondary-green);
                                    width: 100%;
                                }

                                .disabled {

                                    a,
                                    a:active,
                                    a:hover {
                                        opacity: .65;
                                        background-color: var(--secondary-green);
                                        color: var(--secondary-green);
                                        cursor: not-allowed;
                                    }
                                }
                            }

                            // verticl wixard
                            &.vertical-wizard {
                                display: flex;
                                flex-wrap: wrap;

                                // step
                                .steps {
                                    >ul {
                                        flex-direction: column;

                                        >li {
                                            width: 100% !important;
                                        }
                                    }
                                }

                                .steps,
                                .content,
                                .actions {
                                    width: 100%;
                                }

                                .steps {
                                    @media (min-width: 1200px) {
                                        width: 25%;
                                    }
                                }

                                .content {
                                    padding: 24px;

                                    @media (min-width: 1200px) {
                                        width: 75%;
                                        padding: 12px 24px;
                                    }

                                    >.body {
                                        padding: 0;
                                    }
                                }
                            }

                            .muted-text {
                                color: var(--primary-gray);
                                font-weight: 300;
                            }
                        }
                    }

                    // flex-basis: 0;
                    // flex-grow: 1;
                }

                >li {
                    @media (max-width: 765px) {
                        width: 25%;
                    }

                    width: 15%;

                    a {
                        display: block;
                        padding: .5rem 1rem;
                        font-weight: 600;
                        border-bottom: 4px solid transparent;
                        width: 100%;
                        text-align: center;
                        font-size: $font-size-15;
                    }
                }

                .current-info {
                    position: absolute;
                    left: -999em;
                }
            }

            .number {
                display: inline-block;
                width: 38px;
                height: 38px;
                line-height: 34px;
                border: 2px solid var(--primary-green);
                color: var(--secondary-green);
                text-align: center;
                border-radius: 50%;
                margin-right: .5rem;
            }

            .current {

                a,
                a:active,
                a:hover {
                    // background-color: rgba(var(--secondary-green), 0.2);
                    color: var(--secondary-green);
                    border-bottom: 4px solid var(--secondary-green);
                    width: 100%;

                    .number {
                        background-color: var(--secondary-green);
                        color: var(--secondary-green);
                    }
                }
            }
        }

        // content
        >.content {
            background-color: transparent;
            padding: 14px;
            margin-top: 0;
            border-radius: 0;
            // min-height: 150px;
            height: 70vh;

            @media (max-height: 648px) {
                // height: 65vh !important;
                height: 48vh !important;
            }

            @media (min-height: 649px) and (max-height: 699px) {
                height: 50vh !important;
            }

            @media (min-height: 699px) and (max-height: 701px) {
                height: 50vh !important;
            }

            @media (min-height: 701px) and (max-height: 750px) {
                // height: 54vh !important;
                height: 45vh !important;
            }

            @media (min-height: 750px) and (max-height: 850px) {
                // height: 50vh !important;
                height: 43vh !important;
            }

            @media (min-height: 850px) and (max-height: 1022.99px) {
                // height: 44vh !important;
                height: 46vh !important;
            }


            @media (min-height: 1023px) and (max-height: 1100px) {
                // height: 44vh !important;
                height: 34vh !important;
            }

            @media (min-height: 1100px) {
                // height: 35vh !important;
                height: 29vh !important;
            }

            >.title {
                position: absolute;
                left: -999em;
            }

            >.body {
                width: 100%;
                height: 100%;
                padding: 14px 0 0;
                position: static
            }
        }

        // actions

        >.actions {
            position: relative;
            display: block;
            text-align: right;
            width: 100%;

            >ul {
                display: block;
                text-align: right;
                padding-left: 0;

                >li {
                    display: inline-block;
                    margin: 0 0.5em;
                    width: 15%;
                }
            }

            a,
            a:active,
            a:hover {
                background-color: var(--secondary-green);
                border-radius: 4px;
                padding: 8px 15px;
                color: var(--secondary-green);
                width: 100%;
            }

            .disabled {

                a,
                a:active,
                a:hover {
                    opacity: .65;
                    background-color: var(--secondary-green);
                    color: var(--secondary-green);
                    cursor: not-allowed;
                }
            }
        }

        // verticl wixard
        &.vertical-wizard {
            display: flex;
            flex-wrap: wrap;

            // step
            .steps {
                >ul {
                    flex-direction: column;

                    >li {
                        width: 100% !important;
                    }
                }
            }

            .steps,
            .content,
            .actions {
                width: 100%;
            }

            .steps {
                @media (min-width: 1200px) {
                    width: 25%;
                }
            }

            .content {
                padding: 24px;

                @media (min-width: 1200px) {
                    width: 75%;
                    padding: 12px 24px;
                }

                >.body {
                    padding: 0;
                }
            }
        }

        .muted-text {
            color: var(--primary-gray);
            font-weight: 300;
        }
    }

    .increaseHeight {
        // height: auto;
        height: 63vh;
        overflow: scroll;

        @media screen and (min-width: 600px) and (max-width: 768px) {
            height: 590px;
        }

        @media screen and (max-width:600px) {
            height: 480px !important;
        }

        @media screen and (device-width: 1024px) and (device-height: 600px) {
            height: 430px;
        }
    }
}

@media screen and (min-width:1300px) and (max-width: 1380px) and (min-height: 600px) and (max-height: 680px) {
    .linear-quote-calculator {
        left: 60px;

        .header-input-card {
            .card-body {
                padding: 2px 11px !important;
            }
        }

        .linear-view-footer-div {
            margin-top: 5px !important;
            margin-bottom: 5px !important;

            .btn-secondary-add,
            .save-quote {
                height: 32px;
            }
        }
    }

    .quote-calculator {
        padding: calc(60px + 0px) calc(24px * 0.75) 30px calc(24px * 0.75) !important;

        .markup-table-card {
            margin-bottom: 54px !important;
        }

        .grid-view {
            .statistic-card-row {
                top: 60px;
            }

            .ink-count-head {
                width: 130px;
                @media screen and (max-width:600px) {
                    width: 60px !important;
                }
            }

            .art-count-head {
                width: 100px !important;
            }

            .setup-table-head {
                width: 130px;
                @media screen and (max-width:600px) {
                    width: 60px !important;
                }
            }
        }

        .linear-view {
            .item-name-quantity-body-card {
                padding: 6px 20px;
            }

            .calculator-th {
                font-size: 11px;
                padding: 0.45rem 0rem !important;
            }

            .calculator-td {
                padding: 2px 2px !important;
                font-size: 11px !important;
            }

            .stitch-count-calculator-th,
            .setup-calculator-th {
                min-width: 80px !important;
            }

            .ink-count-calculator-th {
                min-width: 101px !important;
            }

            .ink-count-head {
                width: 140px !important;
                @media screen and (max-width:600px) {
                    width: 60px !important;
                }
            }

            .art-count-head {
                width: 100px !important;
            }

            .setup-table-head {
                width: 130px;
                @media screen and (max-width:600px) {
                    width: 60px !important;
                }
            }
        }
    }

    @media (min-width: 576px) {
        .position-sm-static {
            position: static !important;
        }

        .position-sm-relative {
            position: relative !important;
        }

        .position-sm-absolute {
            position: absolute !important;
        }

        .position-sm-fixed {
            position: fixed !important;
        }

        .position-sm-sticky {
            position: sticky !important;
        }
    }

    .setup-td {
        @media screen and (max-width: 600px) {
            min-width: 58px !important;
        }
    }
}
