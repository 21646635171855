.payment-return{

    .animation-container{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 150px;
        margin-right: 150px;

          @media screen and (max-width: 820px) {
            margin-left: 100px;
            margin-right: 100px;
        }

        @media screen and (max-width: 600px) {
            margin-left: 20px;
            margin-right: 20px;
        }

        .animation_card{
            background-color: transparent !important;
            border: 2px solid #e5e7ed;
            border-radius: 12px;

            .animation_card_body{
                padding: 50px !important;

                .payment_status_image{
                    font-size: 100px;
                }
            }
        }
    }
}