.add-tax-container {
    .tax-search-bar {
        border: 2px solid var(--secondary-green);
        height: 39px;
        width: 240px;
        padding-left: 10px;
        border-radius: 0.25rem;
    }

    .no-tax-info {
        max-height: 170px;
        color: var(--primary-gray);
        display: flex;
        align-items: center !important;
    }

    .login-eye {
        color: var(--primary-yellow) !important;
    }

    .option-tax-name {
        width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .search-bar {
        width: 200px !important;

        @media screen and (min-width:1200px) and (max-width: 1400px) {
            // width: 220px !important;
            width: 194px !important;
        }

        @media screen and (min-width: 1400px) and (max-width: 1550px) {
            width: 170px !important;
        }

        @media screen and (min-width: 850px) and (max-width: 1100px) {
            width: 200px !important;
        }

        @media screen and (min-width: 768px) and (max-width: 850px) {
            width: 150px !important;
        }
    }

    .previewQuoteSearchBar {
        width: 150px !important;
        max-width: 200px !important;
    }

    .previewQuote-row {
        padding-left: 12px !important;
    }

    .previewQuote-selected-Dropdown {
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        height: 50px;
    }

    .selected-dropdown {
        border: 1px solid transparent !important;
        height: 45px;
        border-radius: 5px;
        margin-top: 5px !important;
        margin-bottom: 5px !important;

    }

    .dropdown-menu {
        max-height: 260px;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        width: 240px;

        .add-new-tax-btn {
            justify-content: center;
            width: 220px !important;
        }

        .dropdwon-option {
            margin-right: 1px;
            width: 220px !important;
            height: 45px;
            border: 1px solid transparent !important;
            margin-bottom: 5px;
            margin-top: 5px;
            background-color: white;
        }

        .tax-list-hover:hover {
            background-color: var(--primary-light-yellow);
            border: 1px solid var(--primary-yellow) !important;
        }
    }

    .tax-list {
        cursor: default;
    }

    .tax-list:hover {
        background-color: transparent !important;
        border: 1px solid transparent !important;
    }
}