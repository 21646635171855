.standalone-component {
    font-size: 0.8347rem !important;

    @media (max-width: 391px) {
        font-size: 0.6347rem !important;
    }

    .header-div {
        background: var(--primary-white);

        .spinner-border {
            color: var(--primary-green) !important;
        }

        .download-btn:hover {
            .spinner-border {
                color: var(--primary-white) !important;
            }
        }
    }

    .to-section{
        max-width: 300px;
    }

    .subtotal-container {
        width: 300px;
        border-bottom: 3px solid var(--primary-gray);

        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }

    .total-container {
        width: 300px;

        @media screen and (max-width: 576px) {
            width: 200px;
        }
    }

    .selected-client-name-container{
        width: 300px;
        max-width: 300px !important;

        @media screen and (max-width: 600px) {
            padding-right: 16px;
        }
    }

    .description{
        color: var(--secondary-email-text);
    }

    .invoice-name-description {
        width: 90px;

        .invoice-data-container {
            width: 330px !important;
            max-width: 330px !important;
            white-space: break-spaces;

            @media screen and (max-width: 1290px) {
                width: 250px !important;
            }

            @media screen and (max-width: 1055px) {
                width: 200px !important;
            }

            @media screen and (max-width: 768px) {
                width: 200px !important;
            }

            @media screen and (max-width: 576px) {
                width: 150px !important;
                max-width: 200px !important;
            }

            .invoice-info {
                width: 350px;
                max-width: 350px !important;
                white-space: break-spaces;

                @media screen and (max-width: 1290px) {
                    width: 200px !important;
                }

                @media screen and (max-width: 1055px) {
                    width: 200px !important;
                }

                @media screen and (max-width: 768px) {
                    width: 200px !important;
                }

                @media screen and (max-width: 576px) {
                    width: 150px !important;
                    max-width: 200px;
                }
            }
        }
    }

    .download-icon {
        @media screen and (max-width: 576px) {
            display: contents;
        }
    }

    .table-header-rate {
        width: 200px;
        min-width: 100px !important;

        @media screen and (max-width: 768px) {
            width: 130px;
        }

        @media screen and (max-width:568px) {
            width: 100px;
        }
    }

    .tax_detail_row {
        width: 130px !important;

        @media screen and (max-width: 576px) {
            width: 80px !important;
        }

        .tax-display-quote-items-elipsis {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 60px;
            max-width: 60px !important;
            text-align: start;
            color: var(--primary-gray);
        }

        .tax-display-quote-items-popup {
            color: var(--primary-gray);
        }
    }

    .job-name {
        font-weight: 500;
    }

    .from-name {
        color: var(--bs-gray-500);
        font-size: 11px;
    }

    .preview-quote-card {
        border-radius: 11px;
        margin: 0 120px;

        @media screen and (max-width:767.99px) {
            margin: 0 0px;
        }

        @media screen and (min-width:767.99px) and (max-width:991.99px) {
            margin: 0 20px;
        }

        @media screen and (min-width:991.99px) and (max-width:1023.99px) {
            margin: 0 40px;
        }

        @media screen and (max-width:388.99px) {
            .table-responsive {
                padding: 0 !important;
            }
        }
    }

    .name-font-weight {
        font-weight: 700;
    }

    .card-row {
        // margin: 0 120px;
    }

    .span-round {
        height: 7px;
        width: 7px;
        display: inline-block;
        background: var(--bs-gray-500);
        border-radius: 50%;
        margin: 0 6px;
    }

    .card-body {
        border-radius: 24px;
    }

    .preview-quote-title {
        .shop-logo {
            .preview-quote-shop-logo {
                height: 60px !important;
            }
        }
    }

    .gray-text {
        color: var(--bs-dark-border-subtle);
        font-weight: 600;
        font-size: 14px;
    }

    .image-preview {
        height: 28px;
        width: 28px;
    }

    .preview-quote-spinner {
        width: 4rem;
        height: 4rem;
        top: 50%;
        right: 48%;
        color: var(--secondary-green);

        @media screen and (max-width: 539px) {
            right: 40%;
        }

        @media screen and (min-width: 539px) and (max-width: 819px) {
            right: 44%;
        }
    }

    .table-th {
        background-color: var(--secondary-light-green);
    }

    .total-value {
        font-size: 16px;

        @media screen and (max-width:767.99px) {
            font-size: 14px;
        }
    }

    .decline-btn:disabled {
        opacity: 0.4;
        cursor: not-allowed !important;
        pointer-events: all !important;
    }

    .decline-btn:disabled:hover {
        background-color: var(--primary-white) !important;
        border: 1px solid var(--primary-green) !important;
        color: var(--primary-green) !important;
    }

    .check-icon {
        font-size: 30px;
    }

    .success-alert {
        background: var(--secondary-light-green);
    }

    .alert-message {
        border: none !important;
        margin: 0 144px;

        @media screen and (max-width:767.99px) {
            margin: 0 16px;
            font-size: 12px;

            .check-icon {
                font-size: 22px;
            }
        }

        @media screen and (min-width:767.99px) and (max-width:991.99px) {
            margin: 0 41px;
        }
    }
}

.identity-modal {
    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }
}