.tooltip.show {
    opacity: 1 !important;
}

.customtooltip {
    .tooltip-arrow {
        top: 2px !important;

        &::before {
            border-bottom-color: #f46a6acc !important;
        }
    }
}

.plan-container {
    padding-right: 20px;
}