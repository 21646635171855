.pricing-page {

    @media screen and (max-width: 576px) {
        height: auto;
        padding-top: 50px !important;
    }

    .duration-label{
        font-weight: 500; 
        border-bottom: 1px solid #eff2f7;
        font-size: 15px;
    }

    .selected-duration{
        border-bottom: 2px solid var(--secondary-green);
        font-weight: 500;
        font-size: 15px;
        color: var(--secondary-green);
    }

    .category-container {
        margin-bottom: 12px;
    }

    .pricing-description {
        font-size: 15px;
    }

    .plan-duration-switch{
        .plan-duration-button{
            color: var(--primary-green);
            border-color: var(--primary-green);
            &:before{
                height: 20px;
                width: 20px;
                line-height: 20px;
                font-size: 9px;
                background-color: var(--primary-gray);
                color: var(--primary-black);
            }
            &:hover,&.active,&:active{
                color: var(--primary-white);
                background-color: var(--primary-green) ;
            }
        }
    }

    .pricing-card {
        // height: 380px;

        .price-devider {
            font-size: 36px;
        }

        .plan-features {
            height: 98px;
            margin-bottom: 50px;
        }

        .plan-description-container{
            height: 80px;

            @media screen and (max-width: 767px) {
                height: fit-content;
            }

            @media screen and (max-height: 800px) {
                height: 110px;
            }
        }

        .price-desc {
            color: var(--bs-gray-500);
            font-size: 12px !important;
        }

        .price {
            font-weight: 600;
        }

        .pay-now-btn {
            margin-top: -6px;
            font-size: 14px;
            width: 58% !important;
        }
    }

    .pricing-standard-card {
        box-shadow: inset rgba(255, 223, 87, 0.1) 25px 25px 50px 100px;
    }
}