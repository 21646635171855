// 
// _badge.scss
// 


.badge {
  &[href] {
    &:hover,
    &:focus {
      color: var(--#{$prefix}secondary-bg);
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18) !important;

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
  &.bg-#{$color} {
    &[href] {
      &:hover,
      &:focus {
        background-color: darken($value, 4%) !important;
      }
    }
  }
}

.badge{
  &.bg-light{
    color: var(--#{$prefix}body-color);

    &[href] {
      &:hover,
      &:focus {
        color: var(--#{$prefix}body-color);
      }
    }
  }
}

.badge-soft-#{$color} {
      @include badge-variant-soft($value);
  }
  
}

.badge-soft-yellow{
  color: var(--primary-green);
  font-weight: bold;
  border: 1px solid var(--secondary-green);

  .badge-text{
    color: black !important;
    font-weight: normal !important;
  }
}

.rounded-pill{
   padding-right: .6em;
    padding-left: .6em;
}

// Dark badge

.badge.bg-dark{
  color: var(--#{$prefix}light);
}