.forgot-password-page {
    height: 100vh;
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;

    .bg-primary-subtle {
        background-color: var(--secondary-green) !important;
    }

    // cursor not allowed on disable btn
    .btn:disabled {
        cursor: not-allowed !important;
        pointer-events: unset !important;
        background-color: var(--primary-green);
    }

    .logo-img {
        height: 100px !important;
    }

    .form-control {
        border: 2px solid var(--secondary-white) !important;
    }

    .back-login-link {
        line-height: 0 !important;
    }

    .back-to-login:hover .back-login-link {
        text-decoration: underline !important;
        border-bottom: var(--secondary-green);
    }

    .desc-text {
        color: var(--primary-gray);
    }
}